/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

// @font-face {
//   font-family: "Poppins", sans-serif;
//   font-display: swap;
//   src: url('../common/font-family/poppins/Poppins-Black.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-BlackItalic.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-Bold.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-BoldItalic.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-ExtraBold.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-ExtraBoldItalic.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-ExtraLight.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-ExtraLightItalic.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-Italic.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-Light.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-LightItalic.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-Medium.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-MediumItalic.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-Regular.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-SemiBold.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-SemiBoldItalic.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-Thin.ttf') format('ttf'),
//        url('../common/font-family/poppins/Poppins-ThinItalic.ttf') format('ttf'),
// }


@import "./variables";
*,
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  background-color: #000;
  font-family: "Poppins", sans-serif;
  color: #b3b3b3;
  line-height: 1.4;
  font-size: 16px;
}
*,
::after,
::before {
  box-sizing: border-box;
}
section {
  padding: 60px 0;
}
img {
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
}
.seperator {
  width: 1px;
  background: transparent;
  border-right: 1px solid #5b6265;
  height: 16px;
}
.ml-auto {
  margin-left: auto;
}
.ml-5 {
  margin-left: 5px;
}

.mr-zero {
  margin-right: 0px !important;
}

.mr-auto {
  margin-right: auto;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.vertical-middle {
  vertical-align: middle;
}
.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}
.h-30px {
  height: 30px;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.main-title {
  font-size: 3.75rem;
  color: white;
}
.sub-title {
  font-size: 40px;
  color: white;
}
.line-height-36 {
  line-height: 36px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.border-radius-5 {
  border-radius: 5px !important;
}
.border-radius-8 {
  border-radius: 8px !important;
}
.flex-1 {
  flex: 1;
}
.border-width-2 {
  border-width: 2px !important;
}
.white-color {
  color: #ffffff;
}

.text-success {
  color: #008000
}

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Poppins", sans-serif;

$font-size-root: null;
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
);

$font-weights: (
  "font-weight-lighter": lighter,
  "font-weight-light": 300,
  "font-weight-normal": 400,
  "font-weight-medium": 600,
  "font-weight-bold": 700,
  "font-weight-bolder": 800,
);

@each $font in $font-sizes {
  h#{nth($font,1)} {
    font-size: nth($font, 2);
  }
}

@each $weights in $font-weights {
  .#{nth($weights,1)} {
    font-weight: nth($weights, 2);
  }
}

@mixin btn-theme {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 10px 30px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: linear;
}

@mixin btn-white {
  color: #111111;
  background-color: #ffffff;
  border-color: #ffffff;
}

@mixin btn-grey {
  color: #ffffff;
  background-color: #707070;
  border-color: #707070;
}

@mixin btn-yellow {
  color: #000000;
  background-color: #dcba6c;
  border-color: #dcba6c;
}

@mixin btn-outline-white {
  color: #ffffff;
  border-color: #ffffff;
}

.btn {
  @include btn-theme;
  &.btn-white {
    @include btn-white;
  }
  &.btn-outline-white {
    @include btn-outline-white;
  }
  &.btn-grey {
    @include btn-grey;
  }
  &.btn-yellow {
    @include btn-yellow;
  }
}

#loom-companion-mv3 section , #shadow-host-companion {
  padding: 0px !important;
}
.toster-icon {
  font-size: 24px !important;
}

.btn-block {
  display: block;
  width: 100%;
}

.accordion-wrapper {
  overflow: auto;
  height: 100%;

  .accordion {
    color: #ffffff;
    cursor: pointer;
    padding: 22px 0;
    margin-bottom: 5px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 20px;
    transition: 0.4s;
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #333333;
    font-weight: 400;
    .fa-ellipsis-v {
      position: absolute;
      right: 9px;
      font-size: 20px;
      z-index: 999;
    }
    i {
      color: #ffffff;
      font-weight: 400;
      float: right;
      margin-left: 5px;
      position: absolute;
      right: 0px !important;
    }
    .icomoon-icon-cls {
      color: #ffffff;
      font-weight: 400;
      float: right;
      margin-left: 5px;
      position: absolute;
      right: 0px !important;
      font-size: 28px;
    }
  }

  .accordion:hover .icomoon-icon-cls {
    color: #dcba6c !important;
}
  .accordion.active {
    background-color: #000000;
  }

  .panel {
    background-color: #000000;
    // max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    .panel-body {
      padding: 15px 0;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.7;
    }
  }
}
.accordion-wrapper .accordion:hover {
  color: #dcba6c;
}
.accordion-wrapper .accordion:hover i {
  color: #dcba6c;
}
.accordion-wrapper .accordion:last-child {
  border-bottom: 1px solid transparent !important;
}

.footer-section {
  background: #111111;
  position: relative;
  padding-top: 60px;
}

.footer-content {
  position: relative;
  z-index: 2;
  padding-bottom: 15px;
  border-bottom: 1px solid #292929;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 33% 2% 33% 2% 13% 2% 13%;
  grid-template-columns: 33% 33% 13% 13%;
  grid-gap: 2%;
}

.footer-logo {
  margin-bottom: 30px;
  max-width: 70px;
  img {
    max-width: 200px;
  }
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 15px;
  color: #ffffff;
  opacity: 0.7;
  line-height: 28px;
  font-weight: 400;
}
.footer-social-icon {
  .social-circle {
    position: relative;
    color: #fff;
    display: block;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 42px;
    border-radius: 50%;
    border: 2px solid #292929;
    cursor: pointer;
    // margin-bottom: 20px;
    margin-left: 15px;
    &:hover {
      background-color: #292929;
    }
  }
  a {
    color: #fff;
    font-size: 16px;
    margin-right: 0px;
    margin-left: 15px;
  }
  .footer-icon {
    color: #dcba6c;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    &:hover {
        background-color: #292929;
      }
  }
}
.footer-social-main-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.footer-widget-heading {
  h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 22px;
    position: relative;
  }
}

.footer-widget {
  ul {
    li {
      float: left;
      width: 50%;
      margin-bottom: 17px;
      a {
        // text-transform: capitalize;
        font-size: 16px;
        color: #ffffff;
        opacity: 0.7;
        font-weight: 400;

        &:hover {
          color: #dcba6c;
          opacity: 1;
        }
      }
      span {
        // text-transform: capitalize;
        font-size: 16px;
        color: #ffffff;
        opacity: 0.7;
        font-weight: 400;

        &:hover {
          color: #dcba6c;
          opacity: 1;
        }
      }
    }
  }
}

.copyright-area {
  padding: 20px 0;
  .copyright-text {
    p {
      margin: 0;
      font-size: 15px;
      color: #ffffff;
      opacity: 0.7;
    }
  }
}
/* Uncomment and set these variables to customize the grid. */

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.passwordHelpBlock {
  font-size: x-small;
  display: block;
}

.row.reverse {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-xs {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%;
}

.col-xs-2 {
  -ms-flex-preferred-size: 16.667%;
  flex-basis: 16.667%;
  max-width: 16.667%;
}

.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.col-xs-5 {
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%;
}

.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.col-xs-8 {
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%;
}

.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.col-xs-11 {
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%;
}

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-1 {
  margin-left: 8.333%;
}

.col-xs-offset-2 {
  margin-left: 16.667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.333%;
}

.col-xs-offset-5 {
  margin-left: 41.667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.333%;
}

.col-xs-offset-8 {
  margin-left: 66.667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.333%;
}

.col-xs-offset-11 {
  margin-left: 91.667%;
}

.start-xs {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
}

.end-xs {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}

.middle-xs {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

.bottom-xs {
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}

.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.between-xs {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.first-xs {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1;
}

.last-xs {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem;
    margin: 0 auto;
    max-width: 100%;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-sm {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-sm-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-sm-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-sm-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-sm-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-1 {
    margin-left: 8.333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.667%;
  }

  .start-sm {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-sm {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-sm {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-sm {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-sm {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-sm {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

@media only screen and (min-width: 62em) {
  .container {
    width: 61rem;
    margin: 0 auto;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-md {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-md-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-md-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-md-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-md-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-1 {
    margin-left: 8.333%;
  }

  .col-md-offset-2 {
    margin-left: 16.667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.333%;
  }

  .col-md-offset-5 {
    margin-left: 41.667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.333%;
  }

  .col-md-offset-8 {
    margin-left: 66.667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.333%;
  }

  .col-md-offset-11 {
    margin-left: 91.667%;
  }

  .start-md {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-md {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-md {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-md {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-md {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-md {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-md {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

.align-items-center {
  align-items: center;
}
@media only screen and (min-width: 75em) {
  .container {
    width: 75rem;
    margin: 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-lg {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-lg-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-lg-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-lg-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-lg-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-1 {
    margin-left: 8.333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.667%;
  }

  .start-lg {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-lg {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-lg {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-lg {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-lg {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-lg {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}
header.masthead::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #00000070;
  width: 100%;
  height: 100%;
}
header.masthead {
  height: 107vh;
  min-height: 40rem;
  padding-bottom: 0;
  background: url(../images/header-bg.jpg);
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  position: relative;
  .banner-block {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 75rem;
    width: 100%;
    margin: 0 auto;
    left: 50%;
    padding-left: 15px;
    padding-right: 16px;
    h1 {
      font-size: 50px;
    }
  }
}

.navbar {
  width: 100%;
  position: absolute;
  margin-top: 40px;
  .navbar-logo {
    margin-right: 90px;
  }
  .container {
    display: flex;
    align-items: center;
    .navbar-nav {
      display: flex;
      align-items: center;
      .nav-item {
        .nav-link {
          font-size: 15px;
          font-weight: 600;
          color: #fff;
          padding: 0 20px;
          display: flex;
          align-items: center;
          .user-icon {
            width: 40px;
            height: 40px;
            background: #474f54;
            display: inline-block;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

@mixin respond($breakpoint) {
  @if ($breakpoint == laptop) {
    @media (max-width: 1367px) {
      @content;
    }
  }
  @if ($breakpoint == mobile) {
    @media (max-width: 767px) {
      @content;
    }
  }
}

@include respond(laptop) {
  header.masthead .banner-block h1 {
    font-size: 35px;
  }
  .main-title {
    font-size: 40px;
  }
  .sub-title {
    font-size: 30px;
  }
  .discover-section {
    .discover-block {
      h1 {
        font-size: 28px;
      }
    }
  }
}

input[type="range"] {
  -webkit-appearance: none;
  width: 200px;
  height: 10px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient($main-color, $main-color);
  // background-size: 50% 100%;
  background-repeat: no-repeat;
}

/* Input Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: $main-color;
  cursor: $cursor-pointer;
  box-shadow: 0 0 2px 0 $davys-grey-color;
  transition: 0.3s ease-in-out;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: $main-color;
  cursor: $cursor-pointer;
  box-shadow: 0 0 2px 0 $davys-grey-color;
  transition: 0.3s ease-in-out;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: $main-color;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 $davys-grey-color;
  transition: 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: $main-color;
}

input[type="range"]::-moz-range-thumb:hover {
  background: $main-color;
}

input[type="range"]::-ms-thumb:hover {
  background: $main-color;
}

/* Input Track */
input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: $transparent-background;
}

input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: $transparent-background;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: $transparent-background;
}
.more-info-btn {
  margin-right: 10px;
}
.navigation_icon_active {
  color: #dcba6c !important;
}
.Soundboard-music-icon {
  margin-right: 27px;
  font-size: 20px !important;
  color: #c9c4b7 !important;
  opacity: 1 !important;
  position: relative;
  top: -1px;
}
.Soundboard-link-div {
  border-bottom: 1px solid #393939;
  padding-bottom: 30px;
  margin-bottom: 30px !important;
}
.card-box-sec-main-div {
  margin-bottom: 30px;
}
.dashboard-data-table-div {
  margin-top: 20px;
  border-radius: 0px !important;
}
.dashboard-data-table-div .rdt_TableHeadRow {
  border-bottom: 1px solid #333333 !important;
}
// .contact-list-card-main-div .dashboard-data-table-div .rdt_TableHeadRow .rdt_TableCol:last-child {
//   max-width: 175px;
// }
.dashboard-data-table-div .rdt_TableRow {
  border-bottom: 1px solid #333333 !important;
}
.dashboard-data-table-div .rdt_TableRow:last-child {
  border-bottom: 0px !important;
}
.dashboard-data-table-div .rdt_Table {
  border: 1px solid #333333;
  border-right: 0px !important;
}
.dashboard-data-table-div .rdt_TableCell , .dashboard-data-table-div .rdt_TableCol {
  border-right: 1px solid #333333 !important;
  background-color: #222222;
}
.dashboard-data-table-div .sc-eDWCr {
  font-size: 20px;
}
.rdt_Pagination {
  background-color: #222222 !important;
}
.rdt_Pagination select option {
  background-color: #222;
  color: #ffffff;
  border: 1px solid #4e4e4e;
}
.rdt_Pagination select {
  outline: unset !important;
}
.dashboard-datepicker-main-div .react-datepicker__input-container input {
  background-color: #222222;
  height: 40px;
  border-radius: 20px;
  padding: 8px 15px 8px 40px;
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
}
.dashboard-datepicker-main-div .react-datepicker-wrapper {
  width: auto;
}
.dashboard-datepicker-main-div .react-datepicker__input-container input:focus-visible {
  outline: unset !important;
}

.dashboard-datepicker-main-div .react-datepicker {
  background-color: #333333 !important;   
  border: 1px solid #333333 !important;
}
.dashboard-datepicker-main-div .react-datepicker__day {
  color: #ffffff !important;
}
.dashboard-datepicker-main-div .react-datepicker__day--disabled {
  color: #6b6b6b !important;
}
.dashboard-datepicker-main-div .react-datepicker__header {
  background-color: #555555 !important;
  border-bottom: 1px solid #333333 !important;
}
.dashboard-datepicker-main-div .react-datepicker__navigation-icon::before {
  border-color: #dcba6c !important; 
}
.dashboard-datepicker-main-div .react-datepicker__current-month {
  color: #dcba6c !important;
}
.dashboard-datepicker-main-div .react-datepicker__day-name {
  color: #dcba6c !important;
}
.dashboard-datepicker-main-div .react-datepicker__day--keyboard-selected {
  background-color: #dcba6c !important;
  color: #000000 !important;
}
.dashboard-datepicker-main-div .react-datepicker__day:hover {
  background-color: #dcba6c !important;
  color: #000000 !important;
}
.dashboard-datepicker-main-div .react-datepicker__day--in-range {
  background-color: #dcba6c !important;
  color: #000000 !important;
}
.dashboard-datepicker-main-div .react-datepicker__close-icon::after {
  background-color: #dcba6c !important;
  color: #000 !important;
}
.dashboard-search-div input {
  height: 40px;
  margin: 0px;
  border-radius: 30px;
  padding: 10px 40px !important;
}
.dashboard-search-div i {
  top: 13px;
}
.dashboard-select-field-div .css-gfwxh6-placeholder {
  padding: 6px 5px 7px 15px !important;  
  color: #ffffff;
  opacity: 0.7;
}
.dashboard-table-card-div {
  // box-shadow: 0px 2px 10px 0px rgb(255 255 255 / 31%);
  border-radius: 8px;
  padding: 25px 25px;
  background-color: #222222;
}
.dashboard-table-card-div header {
  padding-left: 0px  !important;
  background-color: #222222;
}
.dashboard-table-title-div {
  margin-right: 20px;
}
.dashboard-table-box-div {
  display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    h4 {
      color: #ffffff;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 0px;
    }
    .count-title {
      color: #888888;
      font-size: 14px;
      span {
        color: #dcba6c;
        font-weight: 600;
        opacity: 1;
      }
    }
}
.apex-chart-div {
  max-width: 450px;
  margin: 0 auto;
}
.subscription-main-div {
  display: grid;
  grid-template-columns: 60% 38%;
    column-gap: 30px;
}
.subscription-main-div {
  margin-bottom: 40px;
}
.grid-right-div {
  border-radius: 8px;
}
.apex-chart-div .apexcharts-legend-text {
  color: #ffffff !important;
  opacity: 0.7;
  font-size: 14px !important;
}
.apex-chart-div .apexcharts-legend-series {
  margin: 2px 10px !important;
}
.dashboard-table-card-div .sc-iveFHk {
  background-color: #222222 !important;
  border-right: 1px solid #333;
}
.dashboard-table-card-div .tRpsS {
  background-color: #222222 !important;
  border-right: 1px solid #333;
}

.dashboard-table-card-div .sc-kgTSHT {
  background-color: #222222 !important;
  border-right: 1px solid #333;
}
.dashboard-table-card-div .fUAzDV{
  background-color: #222222 !important;
  border-right: 1px solid #333;
}

.dashboard-table-card-div .sc-kgTSHT div{
  font-size: 16px !important;
  font-weight: 400 !important;
}
.dashboard-table-card-div .fUAzDV div{
  font-size: 16px !important;
  font-weight: 400 !important;
}



@media only screen and (min-width: 320px) and (max-width: 767px) {
  .button-transition-effect:hover {
    transform: translateY(0px) !important;
  }
  .categories-list-box-div .cursor-pointer:hover {
    transform: unset !important;
  }
  .mob-w-100 {
    width: 100%;
  }
  .categories-list-box-div {
    display: flex !important;
    grid-column-gap: 20px !important;
    -moz-column-gap: 20px !important;
    column-gap: 20px !important;
    row-gap: 15px;
  }
  .mob-flex-wrap {
    flex-wrap: wrap;
  }
  .play-now-btn {
    margin-right: 10px;
  }
  .main-title {
    margin-top: 0px !important;
    margin-bottom: 15px !important;
    line-height: inherit;
    font-size: 40px;
  }
  section {
    padding: 50px 0;
  }
  .who-we-offer-space-div {
    margin-top: 0px;
  }
  .who-we-offer-space-div .main-title {
    margin-bottom: 0px !important;
    margin-top: 20px !important;
  }
  .who-we-offer-img img {
    /*  border: 2px solid #222222!important;
    border-radius: 8px; */
  }
  .home_top_main_div .pricing-section {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .pricing-section .mob-pricing-space {
    margin-bottom: 25px !important;
    margin-top: 0px !important;
  }
  .pricing-section .pricing-text-0-7 {
    margin-bottom: 0px !important;
    margin-top: 5px;
    padding-right: 15%;
  }
  .pricing-section .m-b-20 {
    margin-bottom: 10px !important;
  }
  .pricing-accordion-icon {
    position: absolute;
    right: 15px;
  }
  .pricing-up-down-arrow {
    position: absolute;
    right: 15px;
    top: 60px;
    font-size: 28px;
  }
  .pricing {
    position: relative;
  }
  .pricing-title-div {
    margin-bottom: 0px !important;
  }
  .accordion_isActive .pricing-title-div {
    margin-bottom: 40px !important;
  }
  .faq-section .m-b-200 {
    margin-bottom: 50px !important;
  }
  header.masthead .banner-block h1 {
    font-size: 28px;
    position: relative;
    z-index: 1;
  }
  .mob-banner-block p {
    position: relative;
    z-index: 1;
  }
  .mob-homebanner-btn-div {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 40px;
    .more-info-btn {
      margin-bottom: 0px !important;
      color: #ffffff;
      position: relative;
      z-index: 1;
      border: 2px solid #ffffff;
      font-size: 15px !important;
      // width: fit-content !important;
      padding: 10px 15px;
      min-width: 125px;
      i {
        display: none;
      }
    }
    .play-now-btn {
      background: #dcba6c 0% 0% no-repeat padding-box;
      // box-shadow: inset 0px -6px 0px #9e854d;
      border: 2px solid #dcba6c;
      border-radius: 8px !important;
      // padding: 15px 20px 20px 20px;
      position: relative;
      color: #ffffff;
      padding: 10px 10px;
      font-size: 15px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0px !important;
      // width: fit-content !important;
      margin-left: 20px !important;
      min-width: 125px;
      i {
        position: relative;
        left: 0;
        top: 0;
      }
    }
    .active-yellow-btn:hover {
      background-color: #dcba6c !important;
      color: #111111 !important;
    }
    .btn {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      font-size: 18px;
    }
  }
  .box-shadow-btn-mob {
    background: #dcba6c 0% 0% no-repeat padding-box;
    // box-shadow: inset 0px -6px 0px #9e854d;
    border: 1px solid transparent;
    border-radius: 15px !important;
    padding: 15px 15px 15px 15px !important;
    position: relative;
    font-weight: 600;
  }
  .form-group-auth .modal-yellowbtn-hover {
    background: #dcba6c 0% 0% no-repeat padding-box;
    // box-shadow: inset 0px -6px 0px #9e854d;
    border: 1px solid transparent;
    border-radius: 15px !important;
    padding: 15px 15px 15px 15px !important;
    position: relative;
    font-weight: 600;
  }
  .form-group-auth .modal-yellowbtn-hover:hover {
    background: #dcba6c !important;
    color: #111111 !important;
    background-color: #dcba6c !important;
  }
  .mob-margin-space-div {
    margin-top: 45px !important;
  }
  .signup-text-div {
    margin-top: 35px;
    .color-white {
      color: #bbbbbb;
    }
    span {
      display: block;
      font-size: 17px;
      margin-bottom: 8px;
    } 
  }
  .signup_tabs_space-div {
    margin-top: 40px !important;
  }
  .sign_up-padding_space_div {
    padding-top: 35px !important;
  }
  .mob-modal-btn-div .box-shadow-btn-mob {
    border: 1px solid transparent !important;
    border-radius: 15px !important;
    padding: 15px 15px 15px 15px !important;
    font-weight: 600 !important;
    background-color: #dcba6c !important;
    color: #000000 !important;
  }
  .fileUpload_modal_content {
    background-color: #000000 !important;
  }
  .upload-tracks {
    background-color: #000000 !important;
  }
  .mob-modal-cancel-btn {
    background-color: transparent !important;
  }
  .bottom-white-line-div {
    width: 100%;
    max-width: 175px;
    height: 5px;
    border-radius: 15px;
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, -50%);
    display: none;
  }
  progress::-webkit-progress-value {
    background: #dcba6c !important;
    border-radius: 15px;
  }
  progress::-webkit-progress-bar {
    background: #1a1a1a !important;
  }
  .common-contain-para {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 15px !important;
  }
  .card-box-sec-main-div .row .col-sm-6 {
    width: 100% !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .dashboard-datatable-bar-div {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .dashboard-select-field-div {
    margin-right: 0px !important;
    margin-bottom: 15px;
  }
  .dashboard-table-box-div {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .dashboard-table-card-div {
    padding: 15px 15px;
  }
  .dashboard-table-title-div {
    margin-bottom: 15px;
  }
  .dashboard-search-div input {
    width: auto;
  }
  .subscription-main-div {
    grid-template-columns: 1fr;
  }
  .apex-chart-div {
      max-width: 350px;
  }
  .subscription-main-div .grid-left-div {
    margin-bottom: 30px;
  }
  .selectbox {
    min-width: auto !important;
  }
  .dashboard-datepicker-main-div .react-datepicker__input-container input {
    padding: 8px 15px 8px 10px;
  }
  // .music-albums-main-div .react-multi-carousel-list , .tracks-div-global .react-multi-carousel-list {
  //   height: auto !important;
  //   -webkit-overflow-scrolling: touch;
  //     -webkit-backface-visibility: hidden;
  //     backface-visibility: hidden;
  //     overflow: scroll !important;
  //     overflow-x: scroll !important;
  //     overflow-y: hidden;
  //     scroll-behavior: smooth;
  //     transform: translate(0) translateZ(0);
  //     transition: transform .3s ease-in-out;
  // }
  
  // .music-albums-main-div .react-multi-carousel-list::-webkit-scrollbar,
  // .tracks-div-global .react-multi-carousel-list::-webkit-scrollbar
  // {
  //   width: 0px;
  //   height: 0px;
  // }
  // .music-albums-main-div .react-multi-carousel-list::-webkit-scrollbar-thumb , 
  // .tracks-div-global .react-multi-carousel-list::-webkit-scrollbar-thumb {
  //   background: #000000 !important;
  // }
  // .music-albums-main-div .react-multi-carousel-list::-webkit-scrollbar-track ,
  // .tracks-div-global .react-multi-carousel-list
  // {
  //   background-color: #000000 !important;
  // }
}

@media only screen and (min-width: 320px) and (max-width: 474px) {
  .categories-list-box-div .cursor-pointer {
    width: 45% !important;
    height: inherit !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .categories-title-text {
    font-size: 17px !important;
  }
  .mob-upload-text {
    font-size: 17px;
  }
}
@media only screen and (min-width: 475px) and (max-width: 767px) {
  .categories-list-box-div .cursor-pointer {
    width: 200px !important;
    height: inherit !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .categories-title-text {
    font-size: 20px !important;
  }
  .mob-upload-text {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories-title-text {
    bottom: 10px !important;
    font-size: 16px !important;
  }
  .categories-list-box-div .cursor-pointer {
    width: 160px !important;
    height: inherit !important;
    margin-bottom: 18px !important;
  }
  .categories-list-box-div {
    display: flex !important;
    // grid-column-gap: 20px !important;
    // -moz-column-gap: 20px !important;
    // column-gap: 20px !important;
  }
  .footer-content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 50% 30px 50%;
    grid-template-columns: 50% 45%;
    grid-gap: 30px;
  }
  .common-contain-para {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 15px !important;
  }
  .subscription-main-div {
    grid-template-columns: 100%;
  }
  .subscription-main-div .grid-left-div {
    margin-bottom: 30px;
}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories-title-text {
    bottom: 15px !important;
    font-size: 18px !important;
  }
  .categories-list-box-div .cursor-pointer {
    width: 200px !important;
    height: inherit !important;
    margin-bottom: 18px !important;
  }
  .categories-list-box-div {
    display: flex !important;
  }
  .footer-content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 50% 30px 50%;
    grid-template-columns: 50% 50%;
    grid-gap: 30px;
  }
  .home_footer .footer-content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 31% 2% 34% 2% 14% 2% 14% !important;
    grid-template-columns: 31% 34% 14% 14% !important;
    grid-gap: 2% !important;
  }
  .common-contain-para {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 15px !important;
  }
  .subscription-main-div {
    grid-template-columns: 100%;
  }
  .subscription-main-div .grid-left-div {
    margin-bottom: 30px;
}
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .categories-title-text {
    bottom: 10px !important;
    font-size: 18px !important;
  }
  .categories-list-box-div .cursor-pointer {
    width: 200px !important;
    height: inherit !important;
    margin-bottom: 18px !important;
  }
  .categories-list-box-div {
    display: flex !important;
  }
  .common-contain-para {
    font-size: 17px;
    line-height: 32px;
    margin-bottom: 20px !important;
  }
  .subscription-main-div {
    column-gap: 20px;
  }
}

.categories-list-box-div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  // column-gap: 30px;
}
.categories-list-box-div .cursor-pointer {
  position: relative;
  width: 205px;
  height: 150px;
  margin-bottom: 30px;
  margin-left: 17px;
  margin-right: 17px;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: linear;
}
.categories-list-box-div .cursor-pointer img {
  border-radius: 8px;
  object-fit: cover;
  filter: brightness(0.8);
}
.categories-list-box-div .cursor-pointer:first-child {
  margin-left: 0px;
}
.categories-list-box-div .cursor-pointer:last-child {
  margin-right: 0px;
}
.categories-list-box-div .cursor-pointer:hover {
  transform: scale(1.1);
  filter: grayscale(0.4);
  transition: transform 0.5s;
}
.categories-title-text {
  position: absolute;
  // z-index: 99;
  left: 0px;
  width: 100%;
  bottom: 15px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
}
.common-btn-width {
  width: 160px;
}
.common-btn-div button {
  padding: 10px 25px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  border: 2px solid transparent;
}
.modal-yellowbtn-hover:hover {
  background-color: transparent !important;
  color: #dcba6c !important;
  border-color: #dcba6c !important;
}
.modal-graybtn-hover:hover {
  background-color: transparent !important;
  border-color: #dcba6c !important;
  color: #dcba6c !important;
}
.header-btn-hover-effact {
  border: 2px solid #dcba6c;
}
.header-btn-hover-effact:hover {
  background-color: transparent !important;
  color: #dcba6c !important;
}
.text-white {
  color: #fff;
}
.bg-dark-gray {
  background-color: #333;
}
.button-bg-gold {
  background-color: #9e854d;
}
.radius-8px {
  border-radius: 8px;
}
.pointer-event {
  cursor: pointer;
}
.justify-content-space-between {
  justify-content: space-between;
}
.d-flex {
  display: flex;
}
progress {
  width: 100%;
  min-width: 160px;
  height: 16px;
  background: #333;
  border-radius: 5px !important;
  background-repeat: no-repeat;
}
progress::-moz-progress-bar {
  background: #dcba6c;
  border-radius: 15px;
}
progress::-webkit-progress-value {
  background: #dcba6c;
  border-radius: 15px;
}
progress::-webkit-progress-bar {
  background: #333;
  border-radius: 15px;
}
.fileUpload_modal_content {
  padding: 15px 15px;
  position: relative;
  background-color: #222;
  margin: auto;
  width: 100%;
  max-width: 700px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.1s;
  animation-name: animatetop;
  animation-duration: 0.1s;
  border-radius: 8px;
}
.close {
  color: #fff;
  float: right;
  font-size: 18px;
  cursor: pointer;
}
.text-light {
  color: #b3b3b3;
}
// i:hover,
// i:hover {
//   color: #dcba6c;
// }
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #666565;
}
::-webkit-scrollbar-track {
  background-color: #a6a6a6;
  border-radius: 10px;
}
html {
  scrollbar-width: thin;
}
.text-white {
  color: #fff;
}

// Add to playlist Model css

.modal_header h4 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
}
.form-group,
.modal-header {
  margin-bottom: 15px;
}
.modal-header {
  margin-bottom: 20px;
}
.footer-modal-sub-div {
  display: flex;
  width: 100%;
}
.footer-modal-sub-div .u_col-6 {
  width: 100%;
}
.footer-modal-sub-div .cancel-btn {
  background-color: #383838;
}
.footer-modal-sub-div .com-btn {
  border: none;
  color: #fff;
  padding: 10px 30px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
}
.footer-modal-sub-div .add-btn {
  background-color: #dcba6c;
  color: #001111 !important;
}
.footer-modal-sub-div .add-btn:hover {
  color: #dcba6c !important;
}
.playlist-select-div .css-jvnggb-singleValue {
  padding: 6px 5px 6px 10px !important;
}
.footer-modal-sub-div .com-btn {
  border: none;
  color: #fff;
  padding: 10px 30px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
}
.margin8 {
  margin: 8px 0;
}

.margin8 .css-m1kaap-control {
  background-color: #333;
  min-height: 40px !important;
}
.margin8 .css-319lph-ValueContainer {
  background-color: #333;
}

// .add_playlist_model_bg {
//   background: rgba(0, 0, 0, 0.02);
// }

.d-grid {
  display: grid;
}

.object_pos {
  object-position: top center;
}

.no-gutters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  max-height: 325px;
  overflow: auto;
  overflow-x: hidden;
}
.styles_chip-input__3aumB .styles_chip__i-PT7 {
  background-color: #9e854d !important;
  border: #9e854d !important;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  padding: 3px 18px 5px 12px;
  position: relative;
  display: flex !important;
  align-items: center !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin-top: 10px;
  word-break: break-word;
  svg {
    position: relative;
    top: 0px;
    fill: #ffffff;
    margin: 0px;
    margin-top: 0px;
  }
}
.styles_chip-input__3aumB .styles_chip__i-PT7:hover {
  background-color: #333333 !important;
}
.styles_chip-input__3aumB {
  outline: 0 !important;
  box-shadow: unset !important;
}
.styles_chip-input__3aumB input:focus {
  outline: 0 !important;
}
.styles_chip-input__3aumB .styles_chip__i-PT7:hover {
  background-color: none;
}

.styles_chip-input__3aumB .styles_chip__i-PT7.styles_show__3kLFl {
  display: initial;
}

.styles_chip-input__3aumB .col {
  padding: 10px 0px 0px 0px;
  width: 100%;
  .custom-form-control {
    padding: 0px 15px;
  }
}

.styles_chip-input__3aumB .p-2 .col-auto {
  padding: 0px 0px 12px 0px;
}

.invite_users {
  position: relative;
  h4 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.Toastify__toast--success {
  background-color: #333333 !important;
  h1 {
    color: #ffffff;
  }
  p {
    color: #ffffff;
    margin-top: 3px;
  }
  .Toastify__close-button {
    color: #ffffff;
    opacity: 1;
  }
}
.Toastify__toast--error {
  background-color: #333333 !important;
  h1 {
    color: #ffffff;
  }
  p {
    color: #ffffff;
    margin-top: 3px;
  }
  .Toastify__close-button {
    color: #ffffff;
    opacity: 1;
  }
}

.arrow_box{
  width: 100px;
}

.selectbox {
  background-color: #222222;
  border-color: #222222;
  color: #ffffff;
  //padding: 4px 12px;
  min-width: 230px;
  border-radius: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: unset;
}

.waveform_website wave{
  cursor: pointer !important;
}

.embedded_player_main{
  display: block;
  position: relative;
}

.embedded_player_img {
  width: 100%;
  max-width: 505px;
  min-height: 505px !important;
  background: #222;
  border-radius: 5px;
  margin-right: 20px;
  object-fit: cover;
  height: 100%;
}
.embedded_player_img span {
  width: 100%;
  border-radius: 8px;
}

.embedded_player_main .rhap_container{
  position: absolute !important;
  bottom: 0px !important;
}

.humburger_radius {

  margin-left: 31px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  

}
.humburger_radius:hover {
  background-color: #222222;
  border-radius: 20px;
  margin-left: 31px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.humburger_radius i{
  margin-left: 0px;
  font-size: 14px;
}
.humburger_radius:hover i{
  margin-left: 0px;
  font-size: 14px;
}
@media only screen and (min-width: 50px) and (max-width: 375px) {
  .embedded_player_img{
    max-width: 100% !important;
    min-height: 325px !important;
  }
  .embedded_player_main {
    // display: block;
    position: static !important;
}
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .embedded_player_img{
    max-width: 100% !important;
    min-height: 355px !important;
  }
  .embedded_player_main {
    // display: block;
    position: static !important;
}
}



//  @media only screen and (min-width: 426px) and (max-width: 550px) {
//   .embedded_player_img{
//     max-width: 100% !important;
//     min-height: 375px !important;
//   }
// }

// @media only screen and (min-width: 551px) and (max-width: 768px) {
//   .embedded_player_img{
//     max-width: 100% !important;
//     min-height: 455px !important;
//   }
// }

.infinite_unset_div .infinite-scroll-component {
  overflow: unset !important;
}

.table-class {
  width: 100%;
    border-collapse: collapse;
  th, td {
    border: 1px solid #333333;
    padding: 7px 10px;
    font-size: 16px;
    min-width: 120px;
  }

}