:root {
  --background-gradient: linear-gradient(to right, rgb(124 124 124 / 0%), rgb(88 88 88 / 50%) 50%, rgb(177 177 177 / 0%) 80%), #333333;
  --background-repeat: repeat-y;
  --background-size: 80px 500px;
  --background-position: 0 0;
  --skeleton-animation: shine 1s infinite;
}

/* Skeleton Loader CSS */
.skeleton-loader-cls {
  background: var(--background-gradient);
  background-repeat: var(--background-repeat);
  background-size: var(--background-size);
  background-position: var(--background-position);
  animation: var(--skeleton-animation);
  opacity: 1 !important;
}

.skeleton-h12 {
  height: 12px;
}
.skeleton-h15 {
  height: 15px;
}
.skeleton-radius5 {
  border-radius: 5px;
}
.skeleton-radius8 {
  border-radius: 8px;
}
.skeleton-radius10 {
  border-radius: 10px;
}

.skeleton-w100 {
  width: 100%;
}
.skeleton-w80 {
  width: 80%;
}
.skeleton-w60 {
  width: 60%;
}
.skeleton-m-t-5 {
  margin-top: 5px;
}
.skeleton-m-t-8 {
  margin-top: 8px;
}
.skeleton-m-t-10 {
  margin-top: 10px;
}
.skeleton-m-t-15 {
  margin-top: 15px;
}

.skeleton-m-b-5 {
  margin-bottom: 5px;
}
.skeleton-m-b-8 {
  margin-bottom: 8px;
}
.skeleton-m-b-10 {
  margin-bottom: 10px;
}
.skeleton-m-b-15 {
  margin-bottom: 15px;
}




@keyframes shine {
  to {
    background-position: 100% 0,
      /* move highlight to right */
      0 0;
  }
}

// category list skeleton css
.CategoryListSkeleton-perent-div {
  display: flex;
  column-gap: 25px;
}
.skeleton-categories-menu {
  height: 54px;
  min-width: 177px;
  background: var(--background-gradient);
  background-repeat: var(--background-repeat);
  background-size: var(--background-size);
  background-position: var(--background-position);
  animation: var(--skeleton-animation);
  border-radius: 40px;
  opacity: 1 !important;
}


// albums list skeleton css
.albumslistskeleton-main-div {
  width: 200px;
  margin-right: 20px;
}
.albumslistskeleton-img-div {
  width: 200px;
  height: 258px;
  margin-top: 20px;
}
.AlbumSkeleton-music-albums-box {
  width: 100%;
  max-width: 200px;
  margin-bottom: 27px;
}



// Tracks List Skeleton CSS
.MyLibraryUploadSkeleton-perent-div {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;
}
.TracksListSkeleton-perent-div {
  display: flex;
  width: 100%;
  column-gap: 25px;
}
.trackslistskeleton-trending-tracks {
  max-width: 342px;
  width: 100%;
}
.trackslistskeleton-main-div {
  display: flex;
  align-items: center;
}
.trackslistskeleton-img-box {
  max-width: 60px;
  height: 60px;
  min-width: 60px;
  margin-right: 20px;
}
.trackslistskeleton-contain-div {
  display: flex;
  width: 100%;
  margin-right: 20px;
  align-items: center;
}
.trackslistskeleton-title-div {
  width: 100%;
  max-width: 200px;
}
.trackslistskeleton-icon-div {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
}
.trackslistskeleton-number-div {
  max-width: 35px;
  height: 35px;
  min-width: 35px;
  margin-right: 23px;
}
.AlbumsDetailsListSkeleton-main-div {
  border-bottom: 1px solid #222222;
    padding-bottom: 10px;
    margin-bottom: 11px;
}
.trackslistskeleton-timer-div {
  max-width: 60px;
  height: 20px;
  min-width: 60px;
  border-radius: 8px;
}
.timer-width {
  width: 25%;
  padding-right: 15px;
}

//MyLibraryPurchaseSkeleton css
.MyLibraryPurchaseSkeleton-main-div {
  border: 1px solid #333333;
    border-radius: 8px;
    padding: 20px 20px 20px 20px;
    margin-bottom: 15px;
}
.PurchaseSkeleton-sub-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PurchaseSkeleton-music-div {
  display: flex;
  align-items: center;
  width: 100%;
}
.PurchaseSkeleton-img-box {
  height: 50px;
  width: 50px;
  min-width: 50px;
  margin-right: 15px;
}
.PurchaseSkeleton-btn-div {
  width: 140px;
  height: 43px;
}
.PurchaseSkeleton-music-name {
  width: 180px;
  margin-right: 25px;
}
.PurchaseSkeleton-track-name {
  width: 100px;
}

.AlbumsDetailsBoxSkeleton-main-div {
  margin-bottom: 10px;
  max-width: 250px;
}
.AlbumsDetailsBoxSkeleton-img-box {
  width: 100%;
  height: 325px;
  margin-bottom: 25px;
}
.AlbumsDetailsBoxSkeleton-contains-div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.AlbumsDetailsBoxSkeleton-title {
  width: 100px;
}
.AlbumsDetailsBoxSkeleton-icon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.tracks-name {
  margin-top: 10px;
  width: 80px;
}
.multi-btn-div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.btn-box {
  height: 30px;
  border-radius: 25px;
  width: 85px;
}


.SuggestionsSearchSkeleton-main-div .trackslistskeleton-trending-tracks {
  max-width: 100%;
  border-bottom: 2px solid #222222;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.AlbumSkeleton-search-perent-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}



// partner skeleton
.partnerstskeleton-box-div {
  min-height: 125px;
  margin-bottom: 30px;
}
.earingskeleton-box-div {
  min-height: 280px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .skeleton-categories-menu {
    height: 41px;
    min-width: 130px;
  }
  .albumslistskeleton-main-div {
    width: 150px;
  }
  .albumslistskeleton-img-div {
    width: 150px;
    height: 195px;
  }
  .TracksListSkeleton-perent-div .trackslistskeleton-trending-tracks:nth-child(2), .TracksListSkeleton-perent-div .trackslistskeleton-trending-tracks:nth-child(3) {
    display: none;
  }
  .trackslistskeleton-trending-tracks {
    max-width: 100%;
  }
  .CategoryListSkeleton-perent-div {
      column-gap: 15px;
  }
  .CategoryListSkeleton-perent-div .skeleton-categories-menu:nth-child(3), .CategoryListSkeleton-perent-div .skeleton-categories-menu:nth-child(4), .CategoryListSkeleton-perent-div .skeleton-categories-menu:nth-child(5) {
    display: none;
  }
  .MyLibraryUploadSkeleton-perent-div {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    gap: 15px;
  }
  .PurchaseSkeleton-sub-div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }
  .MyLibraryPurchaseSkeleton-main-div {
    padding: 15px 10px 15px 10px;
  }
  .PurchaseSkeleton-btn-div {
      width: 110px;
      height: 30px;
      margin-top: 10px;
  }
  .AlbumSkeleton-music-albums-box {
      width: 46%;
      max-width: 100%;
      margin-bottom: 27px;
  }
  .AlbumSkeleton-music-albums-box .albumslistskeleton-main-div {
      width: 100% !important;
  }
  .AlbumSkeleton-music-albums-box .albumslistskeleton-img-div {
    width: 100% !important;
  }
  .trackslistskeleton-number-div {
    max-width: 25px;
    height: 25px;
    min-width: 25px;
    margin-right: 12px;
  }
  .trackslistskeleton-img-box {
      max-width: 50px;
      height: 50px;
      min-width: 50px;
      margin-right: 12px;
  }
  .AlbumsDetailsBoxSkeleton-main-div {
      max-width: 100%;
  }
  .AlbumsDetailsBoxSkeleton-img-box {
      width: 250px;
      height: 325px;
      margin-bottom: 25px;
      margin: 0 auto 20px auto;
  }
  .btn-box {
      height: 40px;
      width: 100%;
  }
  .AlbumSkeleton-search-perent-div .AlbumSkeleton-music-albums-box:nth-child(3) ,.AlbumSkeleton-search-perent-div .AlbumSkeleton-music-albums-box:nth-child(4) , .AlbumSkeleton-search-perent-div .AlbumSkeleton-music-albums-box:nth-child(5) {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skeleton-categories-menu {
    height: 40px;
    min-width: 150px;
  }
  .albumslistskeleton-main-div {
    width: 180px;
  }
  .albumslistskeleton-img-div {
    width: 180px;
    height: 230px;
  }
  .MyLibraryUploadSkeleton-perent-div {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .AlbumSkeleton-music-albums-box {
    max-width: 170px;
    margin-bottom: 25px;
  }
  .AlbumSkeleton-music-albums-box .albumslistskeleton-main-div {
      width: 100% !important;
  }
  .AlbumSkeleton-music-albums-box .albumslistskeleton-img-div {
    width: 100% !important;
  }
  .AlbumsDetailsBoxSkeleton-img-box {
      height: 190px;
  }
  .AlbumSkeleton-search-perent-div .AlbumSkeleton-music-albums-box:nth-child(4) , .AlbumSkeleton-search-perent-div .AlbumSkeleton-music-albums-box:nth-child(5) {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skeleton-categories-menu {
    height: 40px;
    min-width: 155px;
  }
  .albumslistskeleton-main-div {
    width: 180px;
  }
  .albumslistskeleton-img-div {
    width: 180px;
    height: 230px;
  }
  .MyLibraryUploadSkeleton-perent-div {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .AlbumSkeleton-music-albums-box {
    max-width: 170px;
    margin-bottom: 25px;
  }
  .AlbumSkeleton-music-albums-box .albumslistskeleton-main-div {
      width: 100% !important;
  }
  .AlbumSkeleton-music-albums-box .albumslistskeleton-img-div {
    width: 100% !important;
  }
  .AlbumsDetailsBoxSkeleton-img-box {
      height: 265px;
  }
  .AlbumSkeleton-search-perent-div .AlbumSkeleton-music-albums-box:nth-child(5) {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .skeleton-categories-menu {
    height: 44px;
  }
  .albumslistskeleton-main-div {
    width: 180px;
  }
  .albumslistskeleton-img-div {
    width: 180px;
    height: 230px;
  }
  .MyLibraryUploadSkeleton-perent-div {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
  }
  .AlbumSkeleton-music-albums-box {
    max-width: 175px;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1660px) {

  .albumslistskeleton-main-div {
    width: 180px;
  }
  .albumslistskeleton-img-div {
    width: 180px;
    height: 230px;
  }
  .MyLibraryUploadSkeleton-perent-div {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
  }
  
}