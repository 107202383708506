.button_navigation_main_div {
    position: fixed;
    bottom: -1px;
    width: 100%;
    right: 0px;
    z-index: 99999;
    padding: 0px 15px 0px 15px;
    background: #211E16 0% 0% no-repeat padding-box;
    border-radius: 25px 25px 0px 0px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 78px;
    //justify-content: space-between;
    grid-auto-flow: column;
    display: grid
}

.more_menu_active {
    // background-color: #DCBA6C;
    display: block;
    // border-radius: 50%;
    height: 21px;
    width: 21px;
    margin: 0 auto 5px auto !important;

    i {
        color: #DCBA6C !important;
    }
}

// .button_navigation_main_div::-webkit-scrollbar {
//     height: 7px;
//   }
//   .button_navigation_main_div::-webkit-scrollbar-track {
//     background: #ffffff;
//   }
//   .button_navigation_main_div::-webkit-scrollbar-thumb {
//     background: #393425 0% 0% no-repeat padding-box;
//     border-radius: 0px;
//   }
.navigation_icon_div {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;

    // min-width: 75px;
    i {
        color: #C9C4B7;
        font-size: 20px;
        display: block;
        margin-bottom: 6px;
    }

    label {
        margin-bottom: 0px;
        font-size: 13.5px;
        font-weight: 400;
        color: #C9C4B7;
    }
}

.more_menu_main_div {
    background-color: #000000;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 9999;
    padding-left: 15px;
    padding-right: 15px;
}

.more_menu_contain_div {
    margin-top: 20px;

    h2 {
        color: #ffffff;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 35px;
    }
}

.more_menu_links_div {
    .links_div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        p {
            font-size: 20px;
            color: #FFFFFF;
            opacity: 0.7;
            padding-right: 15px;
        }

        i {
            color: #FFFFFF;
            opacity: 0.7;
            font-size: 22px;
            font-weight: 100;
        }
    }
}

.active.links_div p,
.active.links_div i {
    color: #DCBA6C
}


@media only screen and (min-width: 320px) and (max-width: 767px) {
    .more_menu_contain_div {
        h2 {
            color: #ffffff;
            font-size: 23px;
            font-weight: 600;
            margin-bottom: 30px;
        }
    }
}