.waveform_bottom {
  position: fixed;
  bottom: 0px;
  width: calc(100% - 150px);
  right: 0px;
  z-index: 99;
  padding: 12px 10% 12px 10%;
  opacity: 1;
  min-height: 115px;
  backdrop-filter: blur(30px);
  background-color: rgb(255 255 255 / 6%);
}
.music_wave_title {
  color: #ffffff;
  font-size: 15px;
  margin-bottom: 5px;
}

.waveform_bottom_homePage {
  position: fixed;
  bottom: 0px;
  width: calc(100% - 0px);
  right: 0px;
  z-index: 99;
  padding: 11px 0px 11px 0px;
  opacity: 1;
  min-height: 115px;
  backdrop-filter: blur(30px);
  background-color: rgb(255 255 255 / 6%);
}
.music_wave_title {
  color: #ffffff;
  font-size: 15px;
  margin-bottom: 0px;
}
.music_wave_time {
  margin-bottom:0px;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.7  ;
}
.close_wave {
  position: absolute;
  right: 5px;
  top: -8px;
}
.music_wave_controls_div {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.music_wave_controls_div button {
  background-color: black;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  color: #ffffff;
  margin-right: 15px;
}
.music_wave_controls_div button i {
  font-size: 14px;
  color: #ffffff;
}
.waveform_modal {
  // position: fixed;
  // bottom: 0px;
  //background-color: #222222;
  width: 100%;
  padding: 15px 0px;
  margin-top: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .waveform_bottom {
    width: 96%;
    margin: 0 auto;
    left: 0px !important;
    // z-index: 99;
    padding: 15px 15px;
    // bottom: 120px;
    min-height: inherit;
    border-radius: 20px;
    z-index: 999999;
    bottom: 85px;
  }
  .waveform_bottom_homePage {
    width: 96%;
    left: 0px !important;
    z-index: 999999;
    padding: 15px 15px;
    bottom: 85px;
    min-height: inherit;
    border-radius: 20px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .waveform_bottom {
    padding: 12px 2% 12px 2%;
  }
  .waveform_bottom_homePage {
    padding: 11px 0px 11px 0px;
  }
  .music_wave_title {
    font-size: 13px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .waveform_bottom {
    padding: 12px 3% 12px 3%;
  }
  .waveform_bottom_homePage {
    padding: 11px 0px 11px 0px;
  }
  .music_wave_title {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .waveform_bottom {
    padding: 12px 5% 12px 5%;
  }
  .waveform_bottom_homePage {
    padding: 11px 0px 11px 0px;
  }
  .music_wave_title {
    font-size: 14px;
  }
}