.m-t-0 {
  margin-top: 0px !important;
}

.text-danger {
  color: #c31432;
  opacity: 0.9;
}

.toast-success {
  color: #12998e;
}

html {
  scroll-behavior: smooth;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-none {
  pointer-events: none;
}

.active {
  color: #dcba6c !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.p-b-0 {
  padding-bottom: 0px !important;
}

.m-l-0 {
  margin-left: 0px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.m-r-0 {
  margin-right: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-7 {
  margin-top: 7px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.p-l-100 {
  padding-left: 100px !important;
}

.m-r-100 {
  margin-right: 100px !important;
}

.p-r-100 {
  padding-right: 100px !important;
}

.m-t-200 {
  margin-top: 200px !important;
}

.p-t-200 {
  padding-top: 200px !important;
}

.m-b-200 {
  margin-bottom: 200px !important;
}

.p-b-200 {
  padding-bottom: 200px !important;
}

.m-l-200 {
  margin-left: 200px !important;
}

.p-l-200 {
  padding-left: 200px !important;
}

.m-r-200 {
  margin-right: 200px !important;
}

.p-r-200 {
  padding-right: 200px !important;
}

.p-l-5-per {
  padding-left: 5%;
}

.p-r-5-per {
  padding-right: 5%;
}

.t-35 {
  top: 35px !important;
}

.capitalize-text {
  text-transform: capitalize !important;
}

.uppercase-text {
  text-transform: uppercase !important;
}

.lowercase-text {
  text-transform: lowercase !important;
}

.bg-yellow {
  background-color: #dcba6c;
}

.bg-dark-yellow {
  background-color: #9e854d;
}

.outline-dark-yellow {
  border-color: #9e854d;
  border: 1px solid #9e854d;
}

.border-width2 {
  border: 2px solid transparent !important;
}

.color-yellow {
  color: #dcba6c;
}

.bg-white {
  background-color: #ffffff;
}

.color-white {
  color: #ffffff;
}

.bg-black {
  background-color: #000000;
}

.color-black {
  color: #000000;
}

.bg-grey {
  background-color: #b3b3b3;
}

.bg-dark-gray {
  background-color: #333333;
}

.color-grey {
  color: #b3b3b3;
}

.bg-light-black {
  background-color: #222222;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex--align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex--align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.flex--align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex--align-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.flex--align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.flex--justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flex--justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flex--justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex--justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.flex--justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex--justify-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.position-relative {
  position: relative;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.font-weight-lighter {
  font-weight: lighter;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-bolder {
  font-weight: 800;
}

body {
  background-color: #000;
  font-family: "Poppins", sans-serif;
  color: #b3b3b3;
  line-height: 1.4;
  font-size: 16px;
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

section {
  padding: 60px 0;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

.seperator {
  width: 1px;
  background: transparent;
  border-right: 1px solid #5b6265;
  height: 16px;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.vertical-middle {
  vertical-align: middle;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.h-30px {
  height: 30px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.main-title {
  font-size: 60px;
  color: white;
}

.text-yellow-shadow {
  color: #dcba6c;
}

.text-sync-color {
  color: #F16529;
}

.sub-title {
  font-size: 40px;
  color: white;
}

.line-height-36 {
  line-height: 36px;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.flex-direction-column-div {
  display: flex;
  flex-direction: column;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.avoid-clicks {
  pointer-events: none;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-bottom-remove {
  border-bottom: 0px !important;
}

.home-border-bottom {
  border-bottom: 3px solid #dcba6c;
}

.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.__react_component_tooltip a {
  color: #000000;
}

.navbar {
  width: 100%;
  position: absolute;
  margin-top: 40px;
}

.navbar .navbar-logo {
  margin-right: 90px;
}

.navbar .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar .container .navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar .container .navbar-nav .nav-item .nav-link {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.navbar .container .navbar-nav .nav-item .nav-link .user-icon {
  width: 40px;
  height: 40px;
  background: #474f54;
  display: inline-block;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* Uncomment and set these variables to customize the grid. */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.row.reverse {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-xs {
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%;
}

.col-xs-2 {
  -ms-flex-preferred-size: 16.667%;
  flex-basis: 16.667%;
  max-width: 16.667%;
}

.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.col-xs-5 {
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%;
}

.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.col-xs-8 {
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%;
}

.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.col-xs-11 {
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%;
}

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-1 {
  margin-left: 8.333%;
}

.col-xs-offset-2 {
  margin-left: 16.667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.333%;
}

.col-xs-offset-5 {
  margin-left: 41.667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.333%;
}

.col-xs-offset-8 {
  margin-left: 66.667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.333%;
}

.col-xs-offset-11 {
  margin-left: 91.667%;
}

.start-xs {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
}

.end-xs {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}

.middle-xs {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

.bottom-xs {
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}

.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.between-xs {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.first-xs {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1;
}

.last-xs {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem;
    margin: 0 auto;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-sm {
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-sm-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-sm-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-sm-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-sm-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-1 {
    margin-left: 8.333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.667%;
  }

  .start-sm {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-sm {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-sm {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-sm {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-sm {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-sm {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

@media only screen and (min-width: 62em) {
  .container {
    width: 61rem;
    margin: 0 auto;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-md {
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-md-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-md-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-md-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-md-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-1 {
    margin-left: 8.333%;
  }

  .col-md-offset-2 {
    margin-left: 16.667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.333%;
  }

  .col-md-offset-5 {
    margin-left: 41.667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.333%;
  }

  .col-md-offset-8 {
    margin-left: 66.667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.333%;
  }

  .col-md-offset-11 {
    margin-left: 91.667%;
  }

  .start-md {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-md {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-md {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-md {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-md {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-md {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-md {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    width: 75rem;
    margin: 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-lg {
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-lg-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-lg-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-lg-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-lg-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-1 {
    margin-left: 8.333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.667%;
  }

  .start-lg {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-lg {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-lg {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-lg {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-lg {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-lg {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

header.masthead {
  height: 100vh;
  min-height: 40rem;
  padding-bottom: 0;
  background: url(../images/header-bg-mob.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

header.masthead .banner-block h1 {
  font-size: 50px;
}

.footer-section {
  background: #111111;
  position: relative;
  padding-top: 60px;
}

.footer-content {
  position: relative;
  z-index: 2;
  padding-bottom: 15px;
  border-bottom: 1px solid #292929;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 75px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 15px;
  color: #ffffff;
  opacity: 0.7;
  line-height: 28px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 0px;
  margin-left: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  border: 1px solid #dcba6c;
  color: #dcba6c;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget ul li {
  float: left;
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a {
  /* text-transform: capitalize; */
  font-size: 16px;
  color: #ffffff;
  opacity: 0.7;
}

.footer-widget ul li a:hover {
  color: #ffffff;
}

.copyright-area {
  padding: 15px 0;
}

.copyright-area .copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #7e7e7e;
}

.btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 12px 30px;
  font-size: 15px;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.btn.btn-white {
  color: #111111;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff;
}

.btn.btn-grey {
  color: #ffffff;
  background-color: #707070;
  border-color: #707070;
}

.btn.btn-yellow {
  color: #000000;
  background-color: #dcba6c;
  border-color: #dcba6c;
}

.btn.btn-gray {
  color: #000000;
  background-color: #333333;
  border-color: #333333;
}

.btn.btn-black {
  color: #fff9f9;
  background-color: #030303;
  border-color: #333333;
}

.btn-block {
  display: block;
  width: 100%;
}

.outline-yellow {
  border-color: #dcba6c;
  border: 1px solid #dcba6c;
}

.outline-light-black {
  border-color: #222222;
  border: 1px solid #222222;
}

.opacity-0-1 {
  opacity: 0.1;
}

.opacity-0-3 {
  opacity: 0.3;
}

.opacity-0-5 {
  opacity: 0.5;
}

.opacity-0-7 {
  opacity: 0.7;
}

.white-space-nowrap {
  white-space: nowrap;
}

.position-relative {
  position: relative;
}

.accordion-wrapper {
  overflow: auto;
  height: 100%;
}

.com-border-btn {
  border: 2px solid #ffffff;
  border-radius: 8px;
}

.accordion-wrapper .accordion {
  color: #ffffff;
  cursor: pointer;
  padding: 18px 0;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: transparent;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #333333;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.accordion-wrapper .accordion .fa-ellipsis-v {
  position: absolute;
  right: 9px;
  font-size: 20px;
  z-index: 999;
}

.accordion-wrapper .panel {
  background-color: #000000;
  /* max-height: 0; */
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out;
}

.accordion-wrapper .panel .panel-body {
  padding: 15px 0;
}

.accordion-wrapper .panel .panel-body a {
  color: #dcba6c;
}

.categories_section .categories_list .categories_img {
  position: relative;
}

.categories_section .categories_list .categories_img .categories-text {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 20px;
  width: 100%;
  color: #ffffff;
}

.who-we-are-logo {
  background-image: url(../images/logo-yellow.png) !important;
  background-repeat: no-repeat !important;
  height: 100px;
  background-position: center;
}


.who-we-are-section p {
  line-height: 36px;
}

.epic-audio-img {
  background-image: url(../images/epic-audio.jpg) !important;
  background-repeat: no-repeat !important;
  height: 100%;
  background-position: center;
  background-size: contain;
}

.soundboard-img {
  background-image: url(../images/bar-tabs-home.jpg) !important;
  background-repeat: no-repeat !important;
  height: 100%;
  background-position: center;
  background-size: 100%;
}

.cast-img {
  background-image: url(../images/cast-home.jpg) !important;
  background-repeat: no-repeat !important;
  height: 100%;
  background-position: center;
  background-size: 100%;
}


.button-yellow {
  background-color: #9e854d;
}

.discover-section::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #0000004d;
  width: 100%;
  height: 100%;
}

.discover-section {
  height: 106vh;
  min-height: 40rem;
  padding-bottom: 0;
  background: url(../images/discover-bg.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  position: relative;
}

.discover-section .discover-block {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: Rgb(0, 0, 0, 0.5);
  padding: 50px;
  border-radius: 30px;
}

.discover-section .discover-block h1 {
  font-size: 60px;
}

.pricing {
  border: 1px solid #333;
  background-color: #111111;
  font-size: 14px;
  margin-bottom: 0;
  font-family: "Lato";
  border-radius: 8px;
  padding: 20px 20px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: linear;
}

/* .pricing:hover {
  border: 1px solid #dcba6c;
  box-shadow: rgb(220 186 108 / 38%) 0px 20px 30px -10px;
  transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: linear;
    transform: translateY(-10px);
  
} */
.active-select-plan-shadow {
  border: 1px solid #8f8f8f;
  box-shadow: rgb(255 255 255 / 38%) 0px 20px 30px -10px;
}


.pricing li:first-child,
.pricing li:last-child {
  padding: 16px 0px 12px 0px;
}

.pricing li {
  list-style: none;
  padding: 16px 0px 12px 0px;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}

.pricing li+li {
  border-top: 1px solid #333333;
}

.pricing-title-div {
  position: relative;
}

.pricing .amount-text {
  margin-bottom: 0;
  font-size: 30px;
  display: initial;
  position: relative;
  word-break: break-all;
}

.per-month-text {
  position: absolute;
  top: 5px;
  margin-left: 23px;
  color: #ffffff;
  opacity: 0.7;
}

.per-month-text sup {
  font-size: 13px;
  opacity: 0.7;
}

.per-month-checkout-text {
  position: relative;
  top: -5px;
  margin-left: 15px;
  color: #ffffff;
  opacity: 0.7;
}

.per-month-checkout-text sup {
  font-size: 13px;
  opacity: 0.7;
}

.pricing-text-0-7 {
  color: #ffffff;
  opacity: 0.7;
}

.hidden-div {
  visibility: hidden;
}

.bottom-btn-div {
  flex: 1;
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.price-table-text {
  margin-left: 18px;
  position: relative;
  top: 1px;
}

.who-we-offer-space-div {
  margin-top: 97px;
}

.purchasing-detail-main-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #474747;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.purchasing-detail-main-div .users_name {
  color: #ffffff !important;
  font-weight: 200;
  font-size: 15px;
  margin-bottom: 0px !important;
  text-align: left;
  max-width: 175px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: initial;
}

.purchasing-detail-main-div .line_through_text {
  font-size: 13px;
  margin-bottom: 0px !important;
  margin-right: 7px;
}

.purchasing-detail-main-div .main_price_text {
  font-size: 15px;
  margin-bottom: 0px !important;
  position: relative;
  top: -2px;
}

.purchasing-left-side-box {
  display: flex;
}

.purchasing-right-side-box {
  display: flex;
}

.purchasing-small-text {
  font-size: 12px;
  margin-bottom: 25px;
  margin-left: 20px;
  margin-right: 20px;
}

.unloack-section-scroll-div {
  /* max-height: 250px; */
  overflow: auto;
  overflow-x: hidden;
  padding-right: 7px;
}

.subscription-data-div .common_check_box_div label {
  max-width: 175px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.unlock_track_modal {
  margin-top: 40px;
}

.custom-input-filed .form-control {
  height: 50px;
  background-color: #1a1a1a;
  border: 1px solid #1a1a1a;
}

.custom-input-filed .form-control input {
  color: #fff;
  font-size: 14px !important;
}


.custom-select-filed {
  position: relative;
}

.custom-select-filed .selectbox {
  margin-top: 12px;
}

.custom-select-filed .selectbox input {
  height: 36px !important;
}

.custom-select-filed .css-1s2u09g-control {
  border-radius: 8px;
  height: 50px;
}

.custom-select-filed .css-14a5ikv-control {
  border-radius: 8px !important;
}

.custom-select-filed .css-1iuo381-control {
  border-radius: 8px !important;
}

.custom-select-filed .css-154c1q3-singleValue {
  padding: 10px 5px;
}

.subscribe-now-radio-div {
  text-align: center;
}

.item-title-text {
  padding-right: 10px;
}

/* Radio Button Group css */
.radio-btn-group-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* gap: 10px; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #222222;
  border-radius: 35px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.radio-btn-group-div [type="radio"]:checked,
.radio-btn-group-div [type="radio"]:not(:checked) {
  position: absolute;
  left: 14px;
  z-index: 99;
  visibility: hidden;
}

.radio-btn-group-div .checkbox-tools:checked+label,
.radio-btn-group-div .checkbox-tools:not(:checked)+label {
  position: relative;
  display: inline-block;
  padding: 15px 15px;
  color: #111111;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
  border-radius: 40px;
  text-transform: uppercase;
  transition: box-shadow 150ms ease;
  left: 0px;
  font-weight: 500;
  min-width: 180px;
}

.radio-btn-group-div .checkbox-tools:checked+label {
  background-color: #dcba6c;
  border: 1px solid transparent;
  font-weight: 600;
  color: #111111;
}

.radio-btn-group-div span {
  position: relative;
}

.radio-btn-group-div .checkbox-tools:not(:checked)+label {
  /* background-color: #333333; */
  border: 1px solid transparent;
  color: #ffffff;
}

.radio-btn-group-div .checkbox-tools:not(:checked)+label:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.radio-btn-group-div .checkbox-tools:not(:checked)+label:hover,
.radio-btn-group-div .checkbox-tools:checked+label:hover,
.radio-btn-group-div [type="radio"]:hover+label {
  /* box-shadow: 0 0px 12px 0 rgb(220 186 108 / 54%); */
  cursor: pointer;
  -webkit-transition: box-shadow 300ms ease;
  transition: box-shadow 300ms ease;
}

.checkout-pricing-table-div {
  margin-top: 20px;
}

.checkout-change-section-div {
  margin-top: 106px;
}


.song-img-humburger {
  position: absolute;
  right: 0px;
  width: 100%;
  top: 0px;
}

.my-library-search-div {
  position: relative;
}

.my-library-search-div input {
  background-color: #222222 !important;
  border: 1px solid #222222 !important;
}

.my-library-search-div .search-error-msg {
  left: 0px;
  bottom: unset;
}

.my-library-search-div input::placeholder {
  color: #ffffff;
  opacity: 0.7;
  ;
}

.my-library-search-div .search-error-msg .error {
  white-space: unset;
}

.home-faq-section-div {
  padding-top: 95px !important;
}

.button-group-sec .tabs {
  display: flex;
  position: relative;
  background-color: #222222;
  /* box-shadow: 0 0 1px 0 rgb(24 94 224 / 15%), 0 6px 12px 0 rgb(24 94 224 / 15%); */
  border-radius: 99px;
  width: fit-content;
  margin: 0 auto;
}

.button-group-sec .tabs * {
  z-index: 2;
}

.button-group-sec input[type=radio] {
  display: none;
}

.button-group-sec .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  min-width: 180px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  margin-bottom: 0px;
  color: #ffffff;
  text-transform: uppercase;
}


.active_tab {
  color: #000 !important;
}

/* .button-group-sec input[type=radio]:checked + label {
  color: #111111;
} */

.button-group-sec input[id=Annually]:checked~.glider {
  transform: translateX(0);
}

.button-group-sec input[id=Semi-annually]:checked~.glider {
  transform: translateX(100%);
}

.button-group-sec input[id=Monthly]:checked~.glider {
  transform: translateX(200%);
}

.button-group-sec .glider {
  position: absolute;
  display: flex;
  height: 50px;
  min-width: 180px;
  background-color: #dcba6c;
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

/* @media (max-width: 700px) {
  .button-group-sec .tabs {
    transform: scale(0.6);
  }
} */
.button-transition-effect:hover {
  transition-duration: .3s;
  transition-property: transform;
  /* transition-timing-function: ease-out; */
  transition-timing-function: linear;
  transform: translateY(-8px);
}

.home_top_main_div .modal_header h4 {
  font-weight: 500;
}

.playlist-text {
  white-space: nowrap;
}

.css-wio573-placeholder {
  padding-left: 7px;
  color: #ffffff !important;
}

.contact-us-sec-main-div .css-gle8uw-singleValue {
  padding-left: 7px !important;
}

.contact-us-sec-main-div .css-iislnz-control {
  background-color: #333333 !important;
}

.contact-us-sec-main-div .css-7bkpyv-control {
  background-color: #333333 !important;
}

.upgrade-plan-sec .main-title {
  margin-top: 0px !important;
}

.playlist-modal-title {
  font-weight: 500 !important;
}

.signup-btn-package {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 60px auto 0 auto;
}

.albums-footer-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.albums-tracks-div {
  width: 100%;
}

.albums-humburger-div {
  margin-left: 10px;
}

.albums-bar-sec .css-w8az5c-placeholder {
  padding: 10px 5px 10px 13px;
}

.albums-bar-sec .css-jvnggb-singleValue {
  padding: 10px 5px 10px 13px;
}

.share-icon-main-div {
  display: flex;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  justify-content: center;
}

.share-icon-box {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-icon-box i {
  font-size: 30px;
  color: #ffffff !important;
}

.facebook-icon-box {
  background-color: #418EEB;
}

.twitter-icon-box {
  background-color: #4DA0EB;
}

.linkdin-icon-box {
  background-color: #3376B0;
}

.link-icon-box {
  background-color: #3f51b5;
}

.youtube-icon-box {
  background-color: #EA3223;
}

.social-icon-box {
  text-align: center;
}

.social-icon-box p {
  font-size: 14px;
  color: #ffffff;
  margin-top: 7px;
  font-weight: 400;
}

/* Share Albums modal */
/* .modal_header .modal_main_title {
  text-align: left;
  justify-content: flex-start;
} */
.modal_content_share_album .modal_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal_content_share_album .modal_header .modal_main_title {
  margin-bottom: 0px;
}

.modal_content_share_album .modal_header .modal_close_icon {
  position: relative;
  top: 0px;
  right: 0px;
}

.modal_content_share_album .modal_header.m-b-40 {
  margin-bottom: 45px !important;
}

.modal_content_share_album .modal_header .m-r-20 {
  display: none;
}

.albums-share-humburger-menu {
  position: absolute;
  top: 10px;
  /* right: -15px; */
}

.accept-invitation-bg-banner {
  min-height: 100vh;
  padding-bottom: 0;
  background: url(../images/accept-invitation-bg.jpg);

  background-position: right;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  position: relative;
}

/* .accept-invitation-bg-banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.439);
  width: 100%;
  height: 100%;
} */


.accept-invitation-modal-main-div {
  border: 1px solid #dcba6c91;
  background-color: #0f0f0fd6;
  border-radius: 8px;
  padding: 50px 50px;
  box-shadow: 0 10px 20px rgb(220 186 108 / 22%), 0 6px 6px rgb(220 186 108 / 12%);
}

.accept-invitation-form-main-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.Invitation-contain-sec {
  border-right: 1px solid #dcba6c91;
  margin-right: 35px;
  padding-right: 35px;
}

.Invitation-form-sec h2 {
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 15px !important;
}

.Invitation-formfield-div {
  margin-bottom: 15px;
}

.checkout-filed-div .form-control {
  height: 42px;
  padding: 7px 15px 7px 15px;
}

.checkout-select-field .selectbox input {
  height: 25px !important;
}

.checkout-select-field .css-14a5ikv-control {
  min-height: 42px !important;
  height: 42px !important;
}

.checkout-select-field .css-gfwxh6-placeholder {
  padding: 8px 5px 10px 15px;
  opacity: 0.7;
}

.checkout-select-field .css-1iuo381-control {
  min-height: 42px !important;
}

.checkout-select-field .css-1p7m7gu-singleValue {
  padding: 5px 5px 5px 15px;
  opacity: 0.7;
}

.email-verification-modal-main-div {
  background: rgba(0, 0, 0, 0.65);
  box-shadow: 0px 8px 20px rgba(220, 186, 108, 0.4);
  border-radius: 25px;
  padding-top: 85px;
  padding-bottom: 85px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.email-verification-modal-main-div .para-text {
  font-weight: 400;
  color: #ffffff;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  margin-top: 45px;
}


.email-verification-modal-main-div .para-text .color-yellow {
  color: #dcba6c !important;

}

.albums-humburger-div i {
  color: #ffffff;
}

.albums-humburger-div i:hover {
  color: #dcba6c;
}

.info-big-icon {
  font-size: 3rem;
}

.header-left-side-menu {
  position: relative;
}

.trending_tracks-main-div .tracks-humburger-div {
  right: 40px;
}

.checkout-fixed-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
}

.account-modal-main-div {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.account-modal-main-div .login-user-profile-div {
  margin: 0 auto 20px auto;
}

.flex-height {
  flex: 1 1;
}

.account-profile-div .user-name-text {
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 3px;
}

.account-profile-div .user-email-text {
  text-align: center;
  color: #888888;
  font-weight: 400;
  font-size: 16px;
}

.storage-credits-main-div {
  background-color: #1D1D1D;
  padding: 18px;
  border-radius: 15px;
  margin-top: 35px;
}

.storage-credits-card-main-div {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.storage-credits-card {
  background-color: #000000;
  padding: 15px 8px;
  text-align: center;
  border-radius: 15px;
  position: relative;
}

.storage-credits-card i {
  color: #ffffff;
  position: absolute;
  right: 9px;
  top: 7px;
}

.card-small-text {
  color: #ffffff !important;
  opacity: 0.7 !important;
  margin-bottom: 5px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

.big-small-text {
  color: #DCBA6C;
  font-weight: 700;
  font-size: 18px !important;
}

.credits-btn-div .btn {
  color: #ffffff;
}

.sign-out-link-div {
  text-align: center;
}

.sign-out-link-div .sign-out-link {
  text-align: center;
  color: #ffffff;
  font-size: 17px;
}

.title-text-black {
  color: #000000 !important;
  font-weight: 600;
}










/* Responsive css */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .home_top_main_div .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mob-remove-padding .dashboard_select_width {
    width: 290px !important;
  }

  .trending_tracks-main-div .tracks-humburger-div {
    right: 20px;
  }

  .mob_top_space {
    margin-top: 40px;
  }

  .dashbord-title-mt {
    margin-top: 30px;
  }

  .mob-remove-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .line_div {
    width: 100%;
    margin-bottom: 35px;
    margin-top: 35px;
    border: 1px solid #474747;
    border-bottom: 0px;
  }

  .mobfooter-mb-150 {
    margin-bottom: 150px;
  }

  .mobfooter-mb-100 {
    margin-bottom: 100px;
  }

  .search-opened-main-div {
    min-height: inherit;
  }

  .header-user-dropdown .menu {
    max-width: 180px;
    right: 0px;
    margin-top: 15px !important;
  }

  .header-user-dropdown .profile .menu li a {
    font-size: 14px;
  }

  .header-user-dropdown .profile {
    cursor: none;
  }

  .header-user-dropdown .profile .menu li {
    padding: 8px 12px;
  }

  .header-small-menu-div {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    cursor: none;
    position: relative;
    top: 3px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pricing-section .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .pricing-section.m-b-100 {
    margin-bottom: 30px !important;
  }

  .store_header_main_div {
    width: 100% !important;
  }

  .store_header_main_div {
    padding: 15px 15px !important;
    background-color: #000000 !important;
    box-shadow: none !important;
    height: inherit !important;
  }

  .header_background {
    background-color: #111111 !important;
    /* box-shadow: 0px 1px 0px #ffffff33 !important; */
  }

  .store_header_main_div .brand-logo {
    display: block !important;
    padding: 0px !important;
    margin-right: 0px !important;
    position: relative;
    z-index: 99;
  }

  .store_header_main_div .header-bar-profile .user-profile-div {
    top: 0px;
    width: fit-content;
    margin-left: auto;
  }

  .store_header_main_div .header_main_div .show-menu-btn {
    top: 4px;
  }

  .home_top_main_div .footer-section {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 95px;
  }

  .footer-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer-sec-div {
    flex-wrap: wrap;
  }

  .footer-sec-div .text-lg-left,
  .footer-sec-div .text-right {
    width: 100%;
    text-align: center;
  }

  .footer-sec-div .copyright-text {
    text-align: center;
  }

  .copyright-area .copyright-text p {
    margin-bottom: 15px;
  }

  header.masthead .banner-block {
    text-align: center;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-right: 10px;
  }

  header.masthead {
    background-position: right;
    min-height: auto;
    height: inherit !important;
    background: unset !important;
    min-height: inherit !important;
    padding-top: 85px;
  }

  header.masthead .mob-banner-block {
    padding: 15px;
    background: url(../images/header-bg-mob.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    border-radius: 15px;
    left: 0px !important;
    top: 0px !important;
    transform: unset !important;
    position: relative !important;
    border: 0px solid #333333 !important;
    border-radius: 0px;
    min-height: 350px;
  }

  header.masthead::before {
    display: none;
  }

  header.masthead .mob-banner-block::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #00000054;
    width: 100%;
    height: 100%;
  }

  header.masthead .col-sm-12 {
    width: 100%;
  }

  header.masthead .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  header.masthead .mob-homebanner-btn-div {
    display: flex;
    flex-direction: unset;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }

  .mob-homebanner-contains-div {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }

  .mob-homebanner-contains-div h1 {
    margin-bottom: 0px !important;
  }

  .mob-homebanner-contains-div p {
    margin-bottom: 0px !important;
  }

  /* .header.masthead .row {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  } */
  .categories_section .categories_list {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: -webkit-box;
  }

  .categories_section .categories_list .categories_img {
    margin-right: 15px;
  }

  .categories_section .categories_list .categories_img:last-child {
    margin-right: 0px;
  }

  section {
    padding: 30px 0;
  }

  .who-we-are-section img {
    width: 70px !important;
  }

  .who-we-are-section p {
    line-height: 28px;
    font-size: 15px;
  }

  .who-we-offer-section .sub-title {
    text-align: center;
    margin-bottom: 20px !important;
    margin-top: 20px;
    font-weight: 500;
    font-size: 28px;
  }

  .who-we-offer-section .fs-18 {
    line-height: 28px;
    font-size: 15px;
    text-align: center;
  }

  .who-we-offer-section .p-b-100 {
    padding-bottom: 0px !important;
  }

  .who-we-offer-section .p-t-100 {
    padding-top: 0px !important;
  }

  .who-we-offer-section .mob-m-b-100 {
    margin-bottom: 70px !important;
  }

  .column-reverse-div {
    flex-direction: column-reverse !important;
  }

  .mob-top-who-we-offer {
    margin-top: 26px;
  }

  .discover-section .discover-block:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #0000004d;
    width: 100%;
    height: 100%;
  }

  .discover-section .discover-block h1,
  .discover-section .discover-block .fs-18,
  .discover-section .discover-block .btn {
    position: relative;
    z-index: 9;
  }

  .discover-section .discover-block {
    padding: 15px;
    background: url(../images/discover-bg-mob.jpg);
    /* background: url(../images/discover-bg.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    position: relative;
    top: 0px;
    transform: unset;
    border: 0px solid #333333 !important;
    border-radius: 0px;
    min-height: 330px;
  }

  .mob-discover-contain-div {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .discover-box-main-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .discover-right-box {
    position: relative;
    top: 35px;
  }

  .mob-sign-up-btn {
    width: 100%;
  }

  .discover-section {
    min-height: auto;
    height: inherit;
    background: unset !important;
    margin-bottom: 50px;
  }

  .discover-section .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .discover-section .discover-block h1 {
    text-align: center;
  }

  .discover-section .discover-block .fs-18 {
    font-size: 16px;
    text-align: center;
    opacity: 1;
    margin-bottom: 0px !important;
    margin-top: 15px;
  }

  .discover-section .discover-block .btn {
    background: #DCBA6C 0% 0% no-repeat padding-box;
    border: 2px solid #DCBA6C;
    border-radius: 8px !important;
    position: relative;
    color: #ffffff;
    padding: 10px 10px;
    font-size: 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    min-width: 90px;
    margin: 0 auto;
  }

  .pricing-section .col-xs-12 {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .pricing-section .col-xs-12:last-child {
    margin-bottom: 0px;
  }

  .pricing .hidden-div {
    display: none;
  }

  .credit-bundles-div {
    margin-bottom: 0px !important;
  }

  .accordion-wrapper .accordion {
    font-size: 16px !important;
    padding-right: 25px !important;
  }

  .accordion-wrapper .panel .panel-body {
    font-size: 15px;
  }

  .faq-section button.m-b-200 {
    width: 100%;
    margin-bottom: 70px !important;
  }

  .pricing .amount-text {
    font-size: 30px;
  }

  .who-we-offer-img {
    text-align: center;
  }

  .mob-top-who-we-offer .col-sm-6 {
    width: 100%;
    text-align: center;
  }

  .masthead button {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .p-r-5-per {
    padding-right: 0px !important;
  }

  .p-l-5-per {
    padding-left: 0px !important;
  }

  .mob-m-t-10 {
    margin-top: 10px;
  }

  .mob-m-t-10 {
    margin-top: 10px;
  }

  .mob-m-l-10 {
    margin-left: 10px !important;
  }

  .mob-m-r-10 {
    margin-right: 10px !important;
  }

  /* .trending-divs .tracks-div-global .react-multi-carousel-track {
    overflow-x: hidden !important;
  } */
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 100% !important;
  }

  .data-not-found-div {
    min-height: inherit;
  }

  .mob-Albums-select-flex-div {
    width: 100%;
  }

  .mob-Albums-select-flex-div .Albums-select-main-div {
    width: 100%;
    margin-right: 0px !important;
  }

  .mob-Albums-title-div {
    margin-bottom: 0px !important;
  }

  .mob-modal-login-btn-div .btn {
    margin-bottom: 10px;
  }

  .mob-modal-login-btn-div .box-shadow-btn-mob {
    color: #ffffff !important;
  }

  .mob-modal-login-btn-div .mob-modal-cancel-btn {
    color: #ffffff !important;
  }

  .login-user-profile-div {
    background-color: #1a1a1a;
    width: 85px;
    height: 85px;
    margin: 0 auto 20% auto;
    border-radius: 50%;
    position: relative;
  }

  .login-user-profile-div i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    color: #c9c4b8;
  }

  .music-albums-main-div {
    min-height: inherit;
  }

  .categories-list-slider-div {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    white-space: nowrap;
    display: grid !important;
  }

  .categories-list-slider-div .react-multi-carousel-list {
    overflow: unset !important;
  }

  .categories-list-slider-div .react-multi-carousel-list .react-multi-carousel-track li:last-child {
    margin-right: 0px !important;
  }

  /* scrollbar css */
  .categories-list-slider-div::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  /* Track */
  .categories-list-slider-div::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  .categories-list-slider-div::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .header_scroll {
    min-height: 75px;
    box-shadow: 0px 1px 0px #ffffff33 !important;
  }

  .multiple_list_main_div_scroll {
    background-color: #222222;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    position: fixed;
    top: 74px;
    left: 0px;
    width: 100%;
    /* transition: 0.6s ease-in-out; */
    /* animation: smoothScroll 1s forwards; */
  }

  .cart-div i {
    margin-left: 0px !important;
  }

  @keyframes smoothScroll {
    0% {
      transform: translateY(-40px);
    }

    100% {
      transform: translateY(0px);
    }
  }

  .my-library-search-div .search-error-msg .error {
    text-align: center;
    margin-top: 1px;
  }

  .upload-select-download-btn-div .loader-img {
    right: unset !important;
    top: 5px !important;
    width: 30px !important;
    left: -10px !important;
  }

  .upload-popup-close-icon {
    top: 0px !important;
  }

  .button-group-sec .tabs {
    display: flex;
    gap: 0;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px;
  }

  .button-group-sec .glider {
    min-width: 115px;
    background-color: transparent;
  }

  .button-group-sec input[type=radio]:checked+label {
    background-color: #dcba6b;
  }

  .button-group-sec .checkbox-tools:checked+label,
  .button-group-sec .checkbox-tools:not(:checked)+label {
    padding: 10px;
    font-size: 13px;
    min-width: 115px;
    margin-bottom: 0;
    height: auto;
  }

  .button-group-sec input[id=Annually]:checked~.glider {
    transform: unset !important;
  }

  .button-group-sec input[id=Semi-annually]:checked~.glider {
    transform: unset !important;
  }

  .button-group-sec input[id=Monthly]:checked~.glider {
    transform: unset !important;
  }

  .unlock_track_modal {
    margin-top: 0px;
  }

  .mob-full-width-btn {
    width: 100%;
  }

  .upgrade-plan-sec .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .custom-select-filed .css-w8az5c-placeholder {
    padding: 10px 5px 10px 20px !important;
  }

  .custom-select-filed .css-jvnggb-singleValue {
    padding: 10px 5px 10px 20px !important;
  }

  .checkout-change-section-div .pricing {
    background-color: #1D1D1D;
    border: 1px solid #1D1D1D;
  }

  .track-purchase-text-div {
    justify-content: center;
  }

  .mob-free-stream-div {
    margin-top: 0px !important;
    margin-bottom: 50px;
  }

  .Invitation-form-sec .btn {
    min-width: 100px;
  }

  .Invitation-formfield-div input {
    margin-left: 10px !important;
  }

  .accept-invitation-bg-banner {
    background-position: left;
  }

  .accept-invitation-form-main-div {
    position: relative;
    top: 0px;
    left: 0px;
    transform: unset;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .accept-invitation-bg-banner {
    min-height: auto;
  }

  .email-verification-modal-main-div {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 500px;
  }

  .email-verification-modal-main-div .para-text {
    font-size: 15px;
    margin-top: 20px;
  }

  .categories-list-btn-slider .react-multi-carousel-track .react-multi-carousel-item {
    margin-right: 10px !important;
  }

  .flex-wrap-div {
    flex-wrap: wrap;
  }

  .total-tracks-size {
    font-size: 12px !important;
  }

  .album-details-btn-main-div {
    flex-wrap: unset !important;
  }

  .album-details-btn-main-div .common-btn-div {
    width: 100%;
  }

  .album-details-btn-main-div .album-details-common-btn {
    width: 100%;
  }

  .active-select-plan-shadow .gray-subscribe-btn-hover:hover {
    background-color: #ffffff !important;
    color: #111111 !important;
  }

  .pricing .btn-gray:hover {
    background-color: #333333 !important;
    color: #fff !important;
    border-color: #333333;
  }

  .pricing .btn-yellow:hover {
    color: #000000 !important;
    background-color: #dcba6c !important;
    border-color: #dcba6c !important;
  }

  .mob-pb-30 {
    padding-bottom: 30px !important;
  }

  .mob-pt-30 {
    padding-top: 30px !important;
  }

  .select-trial-div .css-gfwxh6-placeholder,
  .select-trial-div .css-1p7m7gu-singleValue {
    min-width: 160px !important;
  }

  .dashboard-datatable-bar-div .css-1p7m7gu-singleValue,
  .dashboard-datatable-bar-div .css-gfwxh6-placeholder {
    min-width: 160px !important;
  }

  .infinite-scroll-component {
    overflow: unset !important;
  }

  .albums-humburger-div .track-humburger-menu i {
    margin-right: 10px !important;
  }

  .mob-ml-none {
    margin-left: 0px !important;
  }

  .mob-ml-none i {
    margin-right: 0px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {

  .button-group-sec .checkbox-tools:checked+label,
  .button-group-sec .checkbox-tools:not(:checked)+label {
    padding: 10px;
    font-size: 12px;
    min-width: 85px;
    margin-bottom: 0;
    height: auto;
  }

  .discover-section .discover-block .fs-18 {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 351px) and (max-width: 395px) {

  .button-group-sec .checkbox-tools:checked+label,
  .button-group-sec .checkbox-tools:not(:checked)+label {
    padding: 10px;
    font-size: 12px;
    min-width: 100px;
    margin-bottom: 0;
    height: auto;
  }

  .discover-section .discover-block .fs-18 {
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer-sec-div {
    justify-content: space-between;
  }

  .accordion-wrapper .accordion {
    font-size: 16px !important;
    font-size: 16px !important;
    padding-right: 30px !important;
  }

  .faq-section button.m-b-200 {
    margin-bottom: 70px !important;
  }

  .pricing-section .col-xs-12 {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
  }

  .pricing-section .col-xs-12:nth-child(3),
  .pricing-section .col-xs-12:nth-child(4) {
    margin-bottom: 0px !important;
  }

  .pricing-section .col-xs-12:last-child {
    margin-bottom: 0px;
  }

  .credit-bundles-div {
    margin-bottom: 0px !important;
  }

  section {
    padding: 30px 0;
  }

  .pricing .amount-text {
    font-size: 30px;
  }

  .discover-section .discover-block {
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    padding: 25px;
    max-width: 500px;
  }

  .discover-section {
    min-height: auto;
    height: 75vh;
  }

  .who-we-offer-section .p-b-100 {
    padding-bottom: 50px !important;
  }

  .who-we-offer-section .p-t-100 {
    padding-top: 50px !important;
  }

  .who-we-offer-section .m-b-100 {
    margin-bottom: 0px !important;
  }

  .who-we-are-section img {
    width: 90px;
  }

  header.masthead {
    background-position: right;
    min-height: auto;
    height: 65vh !important;
  }

  header.masthead .banner-block {
    text-align: center;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .categories_section .categories_list {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: -webkit-box;
    display: -webkit-inline-box;
  }

  .categories_section .categories_list .categories_img {
    margin-right: 15px;
  }

  .categories_section .categories_list .categories_img:last-child {
    margin-right: 0px;
  }

  .p-r-5-per {
    padding-right: 0px !important;
  }

  .p-l-5-per {
    padding-left: 0px !important;
  }

  /* .store_top_main_div {
    display: grid;
  } */
  .store_top_main_div .container {
    padding-left: 3%;
    width: 34rem;
  }

  .store_header_main_div {
    padding: 0px 15px !important;
  }

  .header-search-field-div input {
    width: 150px !important;
    margin-right: 5px;
  }

  .user-profile-div .header-name {
    font-size: 11px !important;
    white-space: nowrap;
    max-width: 70px !important;
  }

  .header-right-side-menu .cart-div {
    margin-left: 5px !important;
    white-space: nowrap;
  }

  .cart-div {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .header-user-dropdown .profile .user-icon {
    width: 30px !important;
    height: 30px !important;
    margin-right: 7px !important;
  }

  .user-profile-div .header-name i {
    font-size: 14px !important;
    top: 8px !important;
  }

  .tabs-name-text span {
    font-size: 14px;
  }

  .tabs-name-text {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .header-right-side-menu li {
    padding-left: 5px !important;
  }

  .header-right-side-menu .border-right-div {
    border-right: 1px solid #5b6265;
    padding-right: 5px !important;
  }

  .header-left-side-menu li {
    padding-right: 3px !important;
  }

  .react-multiple-carousel__arrow--right {
    right: calc(0% - -20px) !important;
  }

  .categories-list-slider-div .react-multi-carousel-list .react-multiple-carousel__arrow--right {
    right: calc(0% - -20px) !important;
  }

  .categories-list-slider-div .react-multiple-carousel__arrow--left {
    left: calc(0% - -18px) !important;
  }

  .react-multiple-carousel__arrow--left {
    left: calc(1% - 1px) !important;
  }

  .tracks-div-global {
    min-height: 75px;
  }

  .album-btn-div .common-btn-div .btn {
    padding: 10px 10px !important;
    font-size: 14px !important;
    min-width: 120px;
  }

  .album-btn-div .common-btn-div .common-btn-hover:hover i {
    color: #111111 !important;
  }

  /* .data-not-found-div h1 {
  font-size: 20px;
} */
  .tools-div {
    padding-right: 5px;
  }

  .wavesurfer-sound-div {
    margin-left: 12px !important;
    margin-right: 12px !important;
    max-width: 100px !important;
  }

  .wavesurfer-soundbar-div {
    right: 4px !important;
  }

  .search-error-msg .error {
    font-size: 13px;
  }

  .storage-text-div {
    margin-right: 5px;
  }

  .storage-text-div p {
    font-size: 11px !important;
  }

  .minimize-upload-track {
    max-width: 50px !important;
    margin-right: 10px !important;
  }

  .upload-track-title {
    white-space: unset !important;
    font-size: 11px !important;
    font-weight: 400;
    text-align: center;
  }

  .explore-page-section-div .explore-page-spacing {
    padding: 95px 0px 15px 15px !important;
  }

  .explore-page-spacing .react-multiple-carousel__arrow--right {
    right: calc(0% - -20px) !important;
  }

  .albums-title-desk-div {
    margin-bottom: 40px !important;
  }

  .storage-flex-div .seperator {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .info-icon-div {
    margin-left: 10px !important;
  }

  .info-icon-div i {
    font-size: 16px !important;
  }

  .profile-seperator {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .checkout-div-main-div .row .col-lg-4,
  .checkout-div-main-div .row .col-lg-8 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  .footer-sec-div .col-xl-6 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .checkout-change-section-div {
    margin-top: 50px;
  }

  .music-albums-main-div {
    min-height: auto !important;
  }

  .purchased-tracks-btn-div .loader-img {
    position: absolute;
    left: -27px !important;
    top: 1px !important;
    width: 26px !important;
  }

  .purchased-tracks-btn-div {
    text-align: right;
  }

  .upload-select-download-btn-div .loader-img {
    top: -26px !important;
    width: 26px !important;
  }

  .store-albums-loader {
    position: absolute;
    right: 10px;
    top: -3px;
    width: 25px;
  }

  .wavesurfer-main-div {
    max-width: 650px !important;
  }

  .wavesurfer-sound-div i {
    font-size: 20px !important;
  }

  .wavesurfer-sound-main-div {
    display: flex;
  }

  #store {
    padding: 40px 0px;
  }

  .who-we-are-section {
    padding: 40px 0px;
  }

  .who-we-offer-space-div {
    margin-top: 40px;
  }

  .who-we-offer-section .fs-18 {
    font-size: 16px;
    line-height: 30px;
  }

  .who-we-offer-section .sub-title {
    margin-bottom: 20px !important;
  }

  .home-faq-section-div {
    padding-top: 70px !important;
  }

  .home-faq-section-div .m-b-200 {
    margin-bottom: 120px !important;
  }

  #epic-audio {
    margin-bottom: 50px !important;
  }

  .css-w8az5c-placeholder {
    font-size: 13px !important;
    padding: 7px 5px 7px 10px !important;
  }

  .css-14a5ikv-control {
    min-height: 40px !important;
  }

  .css-jvnggb-singleValue {
    font-size: 13px !important;
    padding: 7px 5px 7px 10px !important;
  }

  .css-1iuo381-control {
    min-height: 40px !important;
  }

  .albums-share-humburger-menu {
    /* right: 15px; */
  }

  .Invitation-form-sec h2 {
    font-size: 24px;
  }

  .accept-invitation-modal-main-div {
    padding: 30px 30px;
  }

  .button-transition-effect:hover {
    transform: translateY(0px) !important;
  }

  .accordion-wrapper .accordion:hover {
    color: #ffffff;
  }

  .faq-section .accordion-wrapper .accordion:hover .icomoon-icon-cls {
    color: #ffffff !important;
  }

  .dashboard-datatable-bar-div {
    flex-wrap: wrap;
    gap: 10px;
  }

  .header_main_div .navigations li a i {
    font-size: 18px !important;
    max-width: 50px;
    padding-right: 10px !important;
  }

  .artist_col_6 {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer-sec-div {
    justify-content: space-between;
  }

  .home_footer .footer-section .container {
    width: 61rem !important;
  }

  .footer-section .container {
    width: 42rem;
  }

  .accordion-wrapper .accordion {
    font-size: 16px !important;
    padding-right: 30px !important;
  }

  .faq-section button.m-b-200 {
    margin-bottom: 70px !important;
  }

  .pricing-section .col-xs-12 {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
  }

  .pricing-section .col-xs-12:last-child {
    margin-bottom: 0px;
  }

  .credit-bundles-div {
    margin-bottom: 0px !important;
  }

  section {
    padding: 30px 0;
  }

  .pricing .amount-text {
    font-size: 30px;
  }

  .discover-section .discover-block {
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    padding: 25px;
    max-width: 500px;
  }

  .discover-section {
    min-height: auto;
    height: 75vh;
  }

  .who-we-offer-section .p-b-100 {
    padding-bottom: 50px !important;
  }

  .who-we-offer-section .p-t-100 {
    padding-top: 50px !important;
  }

  .who-we-offer-section .m-b-100 {
    margin-bottom: 0px !important;
  }

  .who-we-are-section img {
    width: 90px;
  }

  header.masthead {
    background-position: right;
    min-height: auto;
    height: 75vh;
  }

  header.masthead .banner-block {
    text-align: center;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .categories_section .categories_list {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: -webkit-box;
  }

  .categories_section .categories_list .categories_img {
    margin-right: 15px;
  }

  .categories_section .categories_list .categories_img:last-child {
    margin-right: 0px;
  }

  .store_header_main_div {
    padding: 0px 15px !important;
  }

  .header-search-field-div input {
    width: 200px !important;
  }

  .user-profile-div .header-name {
    font-size: 13px !important;
    white-space: nowrap;
    max-width: 80px !important;
  }

  .header-right-side-menu .cart-div {
    margin-left: 10px !important;
    white-space: nowrap;
  }

  .header-user-dropdown .profile .user-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .user-profile-div .header-name i {
    font-size: 14px !important;
    top: 8px !important;
  }

  .cart-div {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .header-right-side-menu li {
    padding-left: 15px !important;
  }

  .header-right-side-menu .border-right-div {
    border-right: 1px solid #5b6265;
    padding-right: 15px !important;
  }

  .header-left-side-menu li {
    padding-right: 15px !important;
  }

  .explore-page-spacing .react-multiple-carousel__arrow--right {
    right: calc(0% - -20px) !important;
  }

  .categories-list-slider-div .react-multi-carousel-list .react-multiple-carousel__arrow--right {
    right: calc(0% - -20px) !important;
  }

  .categories-list-slider-div .react-multiple-carousel__arrow--left {
    left: calc(0% - -18px) !important;
  }

  .data-not-found-div h1 {
    font-size: 20px;
  }

  .album-btn-div .common-btn-div .btn {
    padding: 10px 10px !important;
    font-size: 14px !important;
    min-width: 120px;
  }

  .search-error-msg .error {
    font-size: 13px;
  }

  .wavesurfer-soundbar-div {
    right: 2px !important;
  }

  .storage-text-div {
    margin-right: 15px;
  }

  .storage-text-div p {
    font-size: 12px !important;
  }

  .upload-track-title {
    font-size: 13px !important;
  }

  .explore-page-section-div .explore-page-spacing {
    padding: 95px 0px 15px 15px !important;
  }

  .albums-title-desk-div {
    margin-bottom: 40px !important;
  }

  .storage-flex-div .seperator {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .info-icon-div {
    margin-left: 15px !important;
  }

  .info-icon-div i {
    font-size: 18px !important;
  }

  .footer-sec-div .col-xl-6 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .purchased-tracks-btn-div .loader-img {
    left: -40px !important;
    top: 0px !important;
    width: 30px !important;
  }

  .upload-select-download-btn-div .loader-img {
    top: -38px !important;
    width: 30px !important;
  }

  .wavesurfer-main-div {
    max-width: 700px !important;
  }

  .wavesurfer-sound-div i {
    font-size: 20px !important;
  }

  .wavesurfer-sound-main-div {
    display: flex;
  }

  #store {
    padding: 40px 0px;
  }

  .who-we-are-section {
    padding: 40px 0px;
  }

  .who-we-offer-space-div {
    margin-top: 40px;
  }

  .who-we-offer-section .fs-18 {
    font-size: 16px;
    line-height: 30px;
  }

  .who-we-offer-section .sub-title {
    margin-bottom: 20px !important;
  }

  .home-faq-section-div {
    padding-top: 78px !important;
  }

  .home-faq-section-div .m-b-200 {
    margin-bottom: 150px !important;
  }

  #epic-audio {
    margin-bottom: 50px !important;
  }

  .pricing {
    padding: 15px 15px
  }

  .price-table-text {
    margin-left: 10px;
  }

  .css-w8az5c-placeholder {
    font-size: 13px !important;
    padding: 7px 5px 7px 10px !important;
  }

  .css-14a5ikv-control {
    min-height: 40px !important;
  }

  .css-jvnggb-singleValue {
    font-size: 13px !important;
    padding: 7px 5px 7px 10px !important;
  }

  .css-1iuo381-control {
    min-height: 40px !important;
  }

  .checkout-div-main-div .p-l-5-per {
    padding-left: 0%;
  }

  .checkout-change-section-div {
    margin-top: 60px;
  }

  .albums-share-humburger-menu {
    /* right: 5px; */
  }

  .dashboard-datatable-bar-div {
    flex-wrap: wrap;
    gap: 10px;
  }

  .artist-table-main-div {
    padding: 25px 10px !important;
  }

  .artist-tabs-main-div .tabs-links {
    padding: 10px 5px !important;
    min-width: 100px !important;
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .footer-section .container {
    width: 55rem;
  }

  .home_footer .footer-section .container {
    width: 75rem !important;
  }

  .header-search-field-div input {
    width: 230px !important;
  }

  .header-right-side-menu li {
    padding-left: 30px !important;
  }

  .header-right-side-menu .border-right-div {
    border-right: 1px solid #5b6265;
    padding-right: 30px !important;
  }

  .header-left-side-menu li {
    padding-right: 30px !important;
  }

  .react-multi-carousel-list .react-multiple-carousel__arrow--right {
    right: calc(0% - -30px) !important;
  }

  .data-not-found-div h1 {
    font-size: 20px;
  }

  .album-btn-div .common-btn-div .btn {
    padding: 10px 10px !important;
    font-size: 14px !important;
    min-width: 120px;
  }

  .search-error-msg .error {
    font-size: 13px;
  }

  .wavesurfer-soundbar-div {
    right: 2px !important;
  }

  .storage-text-div p {
    font-size: 15px !important;
  }

  .upload-track-title {
    font-size: 14px !important;
  }

  .explore-page-section-div .explore-page-spacing {
    padding: 106px 0px 30px 30px !important;
  }

  .albums-title-desk-div {
    margin-bottom: 40px !important;
  }

  .purchased-tracks-btn-div .loader-img {
    left: -40px !important;
    top: 0px !important;
    width: 30px !important;
  }

  .upload-select-download-btn-div .loader-img {
    top: -38px !important;
    width: 30px !important;
  }

  .wavesurfer-main-div {
    max-width: 850px !important;
  }

  .wavesurfer-sound-div i {
    font-size: 24px !important;
  }

  .wavesurfer-sound-main-div {
    display: flex;
  }

  .who-we-offer-space-div {
    margin-top: 40px;
  }

  .who-we-offer-section .fs-18 {
    font-size: 17px;
    line-height: 34px;
  }

  #epic-audio {
    margin-bottom: 30px !important;
  }

  #soundboard-sec {
    padding-bottom: 30px !important;
  }

  #mixer-sec {
    padding-bottom: 30px !important;
  }

  #casting-sec {
    padding-bottom: 30px !important;
  }

  /* .home-faq-section-div {
    margin-top: 90px;
  } */

  .home-faq-section-div .m-b-200 {
    margin-bottom: 120px !important;
  }

  .home-faq-section-div .accordion-wrapper .accordion {
    font-size: 18px !important;
  }

  .css-w8az5c-placeholder {
    font-size: 13px !important;
    padding: 7px 5px 7px 10px !important;
  }

  .css-14a5ikv-control {
    min-height: 40px !important;
  }

  .css-jvnggb-singleValue {
    font-size: 13px !important;
    padding: 7px 5px 7px 10px !important;
  }

  .css-1iuo381-control {
    min-height: 40px !important;
  }

  .checkout-div-main-div .p-l-5-per {
    padding-left: 2%;
  }

  .checkout-change-section-div {
    margin-top: 70px;
  }

  .categories-list-slider-div .react-multiple-carousel__arrow--left {
    left: calc(0% - -39px) !important;
  }

  .discover-section {
    height: 100vh;
  }

  .artist-tabs-main-div .tabs-links {
    padding: 10px 10px !important;
    min-width: 90px !important;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1660px) {
  .footer-section .container {
    width: 70rem;
  }

  .home_footer .footer-section .container {
    width: 75rem !important;
  }

  .album-btn-div .common-btn-div .btn {
    padding: 12px 15px !important;
    font-size: 14px !important;
    min-width: 120px;
  }

  .storage-text-div p {
    font-size: 16px !important;
  }

  .artist-tabs-main-div .tabs-links {
    padding: 10px 10px !important;
    min-width: 100px !important;
    font-size: 15px;
  }
}

@media only screen and (min-width: 1661px) and (max-width: 1850px) {}

/* Header CSS Start */


.brand-logo img {
  max-width: 69px !important;
}

.link-text.active {
  color: #dcba6c !important;
}

.header_main_div {
  height: 100%;
  width: 100%;
  float: left;
  padding: 0 0px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 40px; */
  z-index: 9;
  position: relative;
  height: 80px;
  border-bottom: 1px solid transparent !important;
}

.header_scroll .header_main_div {
  float: unset !important;
  margin-top: 0px;
}

.header_scroll {
  position: fixed;
  background-color: #111111;
  width: 100%;
  /* -webkit-animation: smoothScroll 1s forwards;
            animation: smoothScroll 1s forwards; */
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}

.header_main_div nav {
  width: 100%;
}

.header_main_div .brand-logo {
  max-width: 75px;
  float: left;
  padding: 10px 0px;
  margin-right: 10%;
  position: relative;
  z-index: 99;
}

.header_main_div .brand-logo div {
  display: flex;
}

.header_main_div .brand-logo img {
  max-width: 100%;
}

.header_main_div .navigations {
  margin: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header_main_div .navigations li {
  list-style: none;
  float: left;
}

.header-left-side-menu li {
  padding-right: 30px;
}

.header-right-side-menu li {
  padding-left: 45px;
}

.header_main_div .navigations li a,
.header_main_div .navigations li span {
  text-align: center;
  display: block;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  /* padding: 0px 20px; */
}

.header_main_div .navigations li a:hover,
.header_main_div .navigations li span:hover {
  color: #dcba6c;
}

.border-btn-hover:hover {
  border-color: #dcba6c !important;
  color: #dcba6c !important;
  background-color: transparent !important;
}

.common-btn-hover:hover {
  background-color: #dcba6c !important;
  border-color: #dcba6c !important;
  color: #111111 !important;
}

.active-yellow-btn:hover {
  border-color: #dcba6c !important;
  color: #dcba6c !important;
  background-color: transparent !important;
}

.yellow-btn-hover:hover {
  background-color: transparent !important;
  color: #dcba6c !important;
}

.upgrade-header-btn {
  padding-left: 5px !important;
}

.subscription-details-btn-div .yellow-btn-hover:hover {
  border-color: #dcba6c !important;
}

.subscribe-btn-hover:hover {
  background-color: transparent !important;
  color: #dcba6c !important;
}

.gray-subscribe-btn-hover:hover {
  background-color: transparent !important;
  color: #dcba6c !important;
  border-color: #dcba6c;
}

.pricing-gray-btn {
  color: #ffffff !important;
}

.header_main_div .navigations li a i {
  width: 100%;
  font-size: 20px;
  margin-bottom: 7px;
}

.header_main_div .show-menu-btn,
.header_main_div .hide-menu-btn {
  transition: 0.4s;
  font-size: 30px;
  cursor: pointer;
  display: none;
}

.header_main_div .show-menu-btn {
  margin: 0px;
  float: right;
  background-color: #727272c4;
  padding: 7px 13px;
  border-radius: 5px;
  font-size: 16px;
  position: relative;
  top: 14px;
}

.header_main_div .show-menu-btn i {
  line-height: 25px;
  line-height: 25px;
  color: #dcba6c;
}

.header_main_div #toggle-btn {
  position: absolute;
  visibility: hidden;
  z-index: -1111;
}

.header_main_div .link-text.joinnow-btn {
  background-color: #0285de;
  color: white;
}

.home_top_main_div .header-user-dropdown {
  margin-left: 30px !important;
}

.header-user-dropdown {
  position: relative;
  cursor: pointer;
  margin-left: 30px;
}

.store_header_main_div .header-user-dropdown {
  margin-left: 0px;
}

.header-user-dropdown .menu {
  background-color: #303a3d;
  display: grid;
  position: absolute;
  margin-top: 10px;
  z-index: 999;
  right: 0px;
  min-width: 173px;
}

.header-user-dropdown .menu li {
  text-align: left;
  padding: 8px 15px;
  border-bottom: 1px solid #4e4e4e;
  list-style: none;
}

.header-user-dropdown .menu li:hover {
  background-color: #dcba6c !important;
}

.header-user-dropdown .menu li:hover a,
.header-user-dropdown .menu li:hover span {
  color: #222222 !important;
}

.header-user-dropdown .menu li a,
.header-user-dropdown .menu li span {
  text-align: left;
  padding-left: 0px;
  color: #ffffff;
  text-decoration: none;
}

.home_top_main_div .header-user-dropdown .profile .user-icon {
  background: #1a1a1a !important;
}

.header-user-dropdown .profile .user-icon {
  width: 40px;
  height: 40px;
  background: #222222;
  display: inline-block;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 15px;
}

.header-user-dropdown .profile .user-icon i {
  font-size: 16px;
}

.user-profile-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 20px;
}

.header-right-side-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user-profile-div .header-name {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-profile-div .header-name i {
  font-size: 16px;
  margin-left: 15px;
  font-weight: 400;
  position: absolute;
  right: 0px;
  top: 11px;
}

.store-header-menu {
  background-color: #222222 !important;
  margin-top: 3px !important;
}

.store-header-menu li {
  line-height: 2 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.border-right-div {
  border-right: 1px solid #5b6265;
  padding-right: 45px !important;
}

.header-bar-profile {
  display: none;
}

.mob-storage-main-div {
  display: none;
}

/*-- Responsive CSS --*/
@media screen and (max-width: 767px) {
  .header-user-dropdown {
    display: none;
  }

  .header-bar-profile {
    display: block;
  }

  .header-bar-profile .header-name {
    display: none;
  }

  .header-bar-profile .user-profile-div {
    justify-content: flex-end;
    position: relative;
    top: 18px;
    width: fit-content;
    margin-left: auto;
    padding-right: 0px;
  }

  .user-profile-div {
    padding-right: 0px;
  }

  .header-search-icon {
    width: 45px;
    height: 45px;
    background: #1a1a1a;
    display: inline-block;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header-search-icon i {
    color: #c9c4b8;
  }

  .header-small-menu-div .cart-div {
    width: 45px;
    height: 45px;
    background: #1a1a1a;
    display: inline-block;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    position: relative;
  }

  .header-small-menu-div .cart-div .cart-number {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
  }

  .header-small-menu-div .cart-div i {
    color: #c9c4b8;
  }

  .header-bar-profile .user-profile-div .fa-angle-down {
    display: none;
  }

  .header-user-dropdown .profile .user-icon {
    margin-right: 0px;
    background-color: #1a1a1a;
    width: 45px;
    height: 45px;
    margin-left: 12px;
  }

  .header-user-dropdown .profile .user-icon i {
    color: #c9c4b8;
    font-size: 18px;
  }

  .header-bar-profile .user-profile-div .fa-chevron-down {
    font-size: 14px;
  }

  .show-menu-btn {
    margin-left: 20px !important;
  }

  .header-right-side-menu .border-right-div {
    border: 0px;
  }

  .header_main_div {
    display: unset !important;
    margin-top: 0px;
    /* position: fixed;
    left: 0px;
    width: 100%;
    top: 0px;
    background-color: #3e3e3e;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 999; */
  }

  .header_main_div .navigations li a,
  .header_main_div .navigations li span {
    color: white;
  }

  .header_main_div .navigations .link-text:hover {
    background: unset !important;
  }

  .header_main_div .link-text.joinnow-btn {
    background-color: #0285de;
    color: white;
    max-width: fit-content;
    margin-left: 15px;
    border-radius: 10px;
    padding: 14px 25px;
    margin-top: 5px;
  }

  .header_main_div .show-menu-btn,
  .header_main_div .hide-menu-btn {
    display: block;
    z-index: 9;
  }

  .header_main_div .navigations {
    position: fixed;
    width: 100%;
    height: calc(100vh - 70px);
    background-color: #222222;
    left: 0;
    padding: 10px 0px;
    transition: 1s;
    z-index: 9;
  }

  .header_main_div .navigations li {
    width: 100%;
  }

  .header_main_div .navigations li a,
  .header_main_div .navigations li span {
    padding: 15px 0px;
    text-align: left;
  }

  .header-right-side-menu li {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header-left-side-menu li {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header_main_div .navigations li .btn {
    padding-left: 15px;
    padding-right: 15px;
    width: fit-content;
  }

  .header_main_div .navigations li a i {
    width: auto;
    font-size: 20px;
  }

  .header_main_div .hide-menu-btn {
    position: absolute;
    top: 15px;
    right: 20px;
  }

  .header_main_div .navigations {
    display: none;
  }

  .header_main_div #toggle-btn:checked~nav .navigations {
    top: 70px;
    display: block;
  }

  .header-right-side-menu {
    display: block;
  }

  .brand-logo img {
    max-width: 48px !important;
  }

  .mob-storage-main-div {
    display: block;
  }

  .storage-flex-div {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 15px;
    margin-top: 15px;
    padding-right: 10px;
  }

  .icon-div {
    margin-left: 25px;
    margin-right: 25px;
    border-right: 1px solid #515151;
    padding-right: 20px;
  }

  .storage-text-div span {
    font-size: 12px;
    color: #ffffff;
    opacity: 0.7;
  }

  .storage-text-div p {
    color: #dcba6c;
    font-size: 17px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo img {
    max-width: 50px !important;
  }

  .header_main_div {
    /* height: 64px; */
  }

  .header_main_div .brand-logo {
    margin-right: 1%;
  }

  .header_main_div .navigations li a,
  .header_main_div .navigations li span {
    padding: 0 8px;
    font-size: 13px;
  }

  .user-profile-div .header-name {
    font-size: 14px;
  }

  .home_top_main_div .user-profile-div .header-name {
    font-size: 13px !important;
  }

  .border-right-div {
    margin-right: 10px;
  }

  .header_main_div {
    margin-top: 0px;
  }

  .store_header_main_div {
    padding: 0px 15px;
  }

  .header_search_field_div input {
    width: 180px;
  }

  .cart_div {
    margin-left: 20px !important;
  }

  .tablate-margin-space {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }

  .home_footer .tablate-margin-space {
    margin-left: 0px !important;
  }

  .header-right-side-menu .header-user-dropdown {
    margin-left: 5px;
  }

  .home_top_main_div .header-user-dropdown {
    margin-left: 5px !important;
  }

  .cart-div .cart-number {
    font-size: 13px !important;
  }

  .cart-div i {
    font-size: 13px;
  }

  .header-user-dropdown .profile .user-icon i {
    font-size: 12px;
  }

  .logout-text {
    padding: 0 10px !important;
  }

  .artist-table-main-div .dashboard-data-table-div .sc-eDWCr {
    font-size: 14px !important;
  }

  .artist-table-main-div .dashboard-data-table-div .rdt_TableCell,
  .artist-table-main-div .dashboard-data-table-div .rdt_TableCol {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header_main_div .brand-logo {
    margin-right: 3%;
  }

  .brand-logo img {
    max-width: 60px !important;
  }

  .header_main_div .navigations li a,
  .header_main_div .navigations li span {
    padding: 0 10px;
  }

  .logout-text {
    padding: 0 15px !important;
  }

  .border-right-div {
    margin-right: 15px;
  }

  .header_main_div {
    margin-top: 0px;
    height: inherit;
  }

  .store_header_main_div {
    padding: 0px 15px;
  }

  .header-search-field-div input {
    width: 200px;
  }

  .cart_div {
    margin-left: 20px !important;
  }

  .minimize-upload-track {
    max-width: 110px !important;
    margin-right: 20px !important;
  }

  .home_top_main_div .header-user-dropdown {
    margin-left: 15px !important;
  }

  .cart-div .cart-number {
    font-size: 14px !important;
  }

  .cart-div i {
    font-size: 14px !important;
  }

  .header-user-dropdown .profile .user-icon i {
    font-size: 12px;
  }

  .artist-table-main-div .dashboard-data-table-div .sc-eDWCr {
    font-size: 15px !important;
  }

  .artist-table-main-div .dashboard-data-table-div .rdt_TableCell,
  .artist-table-main-div .dashboard-data-table-div .rdt_TableCol {
    padding-left: 10px;
    padding-right: 10px;
  }

}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {

  /* Header */
  .header_search_field_div input {
    width: 250px;
  }

  /* Header */

  /* Feature section  */
  .categories_text {
    font-size: 16px !important;
  }

  .categories_section .categories_list .categories_img {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Feature section  */

  /* footer css */
  .store_footer .container {
    max-width: 73%;
  }

  /* footer css */
  .minimize-upload-track {
    max-width: 120px !important;
    margin-right: 20px !important;
  }

  .header-right-side-menu .upgrade-header-btn {
    padding-left: 5px !important;
  }

  .artist-table-main-div .dashboard-data-table-div .sc-eDWCr {
    font-size: 15px !important;
  }

  .artist-table-main-div .dashboard-data-table-div .rdt_TableCell,
  .artist-table-main-div .dashboard-data-table-div .rdt_TableCol {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1660px) {

  /* Header */
  .header_search-field-div input {
    width: 270px;
  }

  .border-right-div {
    padding-right: 30px !important;
  }

  .header-right-side-menu li {
    padding-left: 30px;
  }

  /* Header */

  /* Feature section  */
  .categories_text {
    font-size: 20px !important;
  }

  /* Feature section  */

  /* footer css */
  .store-footer .container {
    max-width: 76%;
  }

  /* footer css */
}

/* STORE HEADER CSS  START*/
/*  Header css  */
.store_header_main_div {
  padding: 0px 40px;
  background-color: #111111;
  box-shadow: 0px 1px 0px #ffffff33;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 999;
  width: calc(100% - 151px);
  height: 80px;
}

.store_header_main_div .header_main_div {
  margin-top: 0px;
  float: unset;
}

.store_header_main_div .navigations li {
  line-height: 5;
}

.store_header_main_div .brand-logo {
  display: none;
}

.store_header_main_div .header_main_div .navigations li a {
  padding: 0px 0px;
}

.header-search-field-div {
  position: relative;
}

.search-error-msg {
  position: absolute;
  width: 100%;
  bottom: -19px;
}

.search-error-msg .error {
  font-size: 13px;
  white-space: nowrap;
}

.header-search-field-div input {
  border: 1px solid #ffffff33;
  height: 40px;
  border-radius: 20px;
  background-color: transparent;
  color: #ffffff;
  padding-left: 40px;
  width: 300px;
  padding-right: 40px;
}

.header-search-field-div input::placeholder {
  color: #ffffff;
  opacity: 0.7;
  font-size: 14px;
}

.header-search-field-div input:focus-visible {
  box-shadow: unset !important;
  outline: unset !important;
}

.common-btn-div .btn {
  padding: 10px 15px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

.header-search-field-div i {
  position: absolute;
  left: 15px;
  top: 13px;
  opacity: 0.7;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}

.header-search-field-div i:hover {
  color: #dcba6c;
}

.cart-div {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;
  cursor: pointer;
}

.cart-div:hover {
  background-color: #222222;
}

.cart-div.active {
  background-color: #222222;
}

.cart-div .cart-number {
  color: #dcba6c;
  font-size: 16px;
  font-weight: 300;
  margin-right: 7px;
}

.cart-div i {
  margin-left: 0px;
  color: #ffffff;
}

.cart-div i:hover {
  color: #ffffff;
}

/* Header css */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #111111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #222222;
}

/* ----------------------------------MODEL CSS START--------------------------------- */
/* radio css */
.common_check_box_div [type="radio"]:checked,
.common_check_box_div [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

input[type="checkbox"] {
  cursor: pointer;
}

.common_check_box_div [type="radio"]:checked+label,
.common_check_box_div [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.common_check_box_div [type="radio"]:checked+label:before,
.common_check_box_div [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #575757;
  border-radius: 100%;
  background: transparent;
}

.common_check_box_div [type="radio"]:checked+label:after,
.common_check_box_div [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #dcba6c;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.common_check_box_div [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.common_check_box_div [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.common_check_box_div label {
  color: #ffffff !important;
  font-weight: 200;
  font-size: 15px;
}

/* radio css */

/* checkbox css */

/* custom checkbox */
.common-checkbox-main-div input[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin: 0;
  padding: 0;
  opacity: 1;
  appearance: none;
  border-radius: 3px;
  border: 1px solid #575757;
  border-radius: 100%;
  background: transparent;
  position: relative;
  margin-right: 10px;
  top: 1px;
}

.common-checkbox-main-div input[type="checkbox"]:checked {
  border: 1px solid #dcba6c;
  background: transparent;
}

.common-checkbox-main-div input[type="checkbox"]:checked:before,
.common-checkbox-main-div input[type="checkbox"]:checked:after {
  content: "";
  position: absolute;
  height: 2px;
  background: #dcba6c;
}

.common-checkbox-main-div input[type="checkbox"]:checked:before {
  width: 6px;
  top: 10px;
  left: 3px;
  transform: rotate(44deg);
}

.common-checkbox-main-div input[type="checkbox"]:checked:after {
  width: 9px;
  top: 8px;
  left: 6px;
  transform: rotate(-55deg);
}

.common-checkbox-main-div input[type="checkbox"]:focus {
  outline: none;
}

/* checkbox css */

/* Modal css start */

.modal {
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  overflow: auto;
  background-color: transparent;
  z-index: 999;
  right: 0;
}

.modal_dialog {
  min-height: 100%;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.modal_content {
  padding: 35px 30px;
  position: relative;
  background-color: #222;
  margin: auto;
  width: 500px;
  max-width: 700px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.1s;
  animation-name: animatetop;
  animation-duration: 0.1s;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 RGB(0 0 0/20%), 0 6px 20px 0 RGB(0 0 0/19%);
}

.modal_main_title {
  text-align: center;
}

.modal_close_icon {
  position: absolute;
  top: 40px;
  right: 20px;
  line-height: 0;
}

.modal_close_icon i {
  font-size: 20px;
  color: #ffffff;
  /* opacity: 0.3; */
}

.modal_close_icon i:hover {
  color: #dcba6c;
  opacity: 1;
}

.header_modal_icon {
  opacity: 0.7;
  color: #ffffff;
}

.modal_main_title {
  color: #ffffff;
  opacity: 0.7;
  font-size: 20px;
  font-weight: 400 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center_line {
  width: 1px;
  min-width: 1px;
  background-color: #ffffff;
  opacity: 0.2;
  max-height: 230px;
  position: relative;
  top: 30px;
}

.plan_left_div {
  width: 100%;
  padding-right: 20px;
}

.plan_right_div {
  width: 100%;
  padding-left: 20px;
}

.modal_small_text {
  opacity: 0.7;
}

.line_through_text {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
  color: #ffffff;
  opacity: 0.7;
}

.line_through_border {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}

.main_price_text {
  font-size: 30px;
}

.bg-yellow {
  background-color: #dcba6c !important;
}

.modal_btn {
  padding: 9px 12px !important;
  min-width: 150px;
}

/* .items_list_main_div {
  min-height: 357px;
}
.purchasing-unloack-sec-div {
  min-height: 249px;
} */
.items_details_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #474747;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.items_name_div {
  width: 100%;
}

.item_price_div {
  width: 100%;
  max-width: 90px;
  text-align: right;
}

.screen_text_div {
  margin-top: 25%;
}

.upload_tracks_modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload_tracks_modal_header .modal_close_icon {
  position: relative;
  top: 0px;
  right: 0px;
}

.upload_tracks_modal_content {
  padding: 20px 20px !important;
}

.wavesurfer-main-div {
  display: flex;
  width: 100%;
  align-items: center;
  max-width: 1082px;
  margin: 0 auto;
}

.wavesurfer-control-div {
  width: 100%;
  max-width: 130px;
}

.wavesurfer-control-div .wavesurfer-sound-div {
  margin-top: 8px;
  justify-content: flex-start;
  max-width: 140px;
}

.wavesurfer-title-wave-div {
  width: 100%;
  position: relative;
}

.wavesurfer-overlay-div {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0px;
  opacity: 0.2;
  z-index: 999;
  backdrop-filter: blur(30px);
  background-color: rgb(255 255 255 / 3%);
}

.wavesurfer-sound-div {
  width: 100%;
  max-width: 100px;
  display: flex;
  position: relative;
  margin-left: 25px;
  margin-right: 25px;
  align-items: center;
}

.wavesurfer-music-title-img-div {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 225px;
}

.wavesurfer-music-title-div p {
  color: #ffffff;
  opacity: 0.7;
}

.wavesurfer-time-main-div {
  margin-right: 25px;
}

.wavesurfer-img-div {
  overflow: hidden;
  border-radius: 8px;
  width: 71px !important;
  height: 91px !important;
  min-width: 71px;
  margin-right: 15px;
}

.wavesurfer-img-div img {
  height: 91px !important;
  object-fit: cover;
}

.wavesurfer-soundbar-div {
  position: absolute;
  right: 2px;
  top: -15px;
  max-width: 125px;
  justify-content: flex-end;
}

.audio-quality-modal-div {
  display: flex;
  flex-direction: column;
}

.audio-quality-modal-div .wavesurfer-control-div {
  width: 100%;
  max-width: 100%;
}

.audio-quality-modal-div .wavesurfer-title-wave-div {
  width: 100%;
  max-width: 100%;
  order: 2;
  margin-bottom: 25px;
}

.audio-quality-modal-div .wavesurfer-sound-div {
  width: 100%;
  max-width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  order: 4;
  text-align: center;
  justify-content: center;
}

.audio-quality-modal-div .wavesurfer-music-title-img-div {
  width: 100%;
  max-width: 100%;
  order: 1;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-bottom: 25px;
}

.audio-quality-modal-div .wavesurfer-music-title-div p {
  font-size: 16px;
}

.audio-quality-modal-div .wavesurfer-music-title-img-div .wavesurfer-music-title-div {
  width: 100%;
  text-align: center;
  margin-top: 6px;
}

.audio-quality-modal-div .wavesurfer-music-title-img-div .wavesurfer-img-div {
  margin-right: 0px;
  width: 80px !important;
  height: 103px !important;
}

.audio-quality-modal-div .wavesurfer-music-title-img-div .wavesurfer-img-div img {
  height: 103px !important;
  -o-object-fit: cover;
  object-fit: cover;
}

.audio-quality-modal-div .wavesurfer-sound-div .wavesurfer-sound-main-div {
  position: absolute;
  top: 43px;
  margin-left: 110px;
}

.audio-quality-modal-div .wavesurfer-sound-div .wavesurfer-soundbar-div {
  right: -25px !important;
}

.audio-quality-modal-div .wavesurfer-sound-div .wavesurfer-time-main-div {
  margin-right: 0px !important;
}

.audio-quality-modal-div .wavesurfer-sound-div .wavesurfer-sound-main-div i {
  font-size: 28px;
}

.audio-quality-modal-div .wavesurfer-close-icon-div {
  display: none;
}

.sampleAudio-playpause-div {
  background-color: #ffffff;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  color: #ffffff;
  margin: 0px auto 0 auto;
  position: relative;
}

.sampleAudio-playpause-div .home-play-pushicon {
  position: absolute;
  color: #000000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
}


/* .wavesurfer-soundbar-div {
  transform: rotate(-90deg) !important;
  position: relative;
  top: -70px;
  left: 20px;
} */
.wavesurfer-play-push-icon-div button {
  background-color: #ffffff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #ffffff;
  margin: 0px 10px;
  position: relative;
}

.wavesurfer-play-push-icon-div button i {
  font-size: 15px;
  color: #846f68;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wavesurfer-play-push-icon-div i {
  color: #ffffff;
  font-size: 22px;
}

.wavesurfer-play-push-icon-div {
  display: flex;
  align-items: center;
}

.wavesurfer-sound-div i {
  color: #ffffff;
  font-size: 24px;
  /* position: absolute; */
  right: 0px;
  opacity: 0.7;
  font-weight: 300;
}

.error_msg_fileupload {
  position: absolute;
  font-size: 13px;
  left: 2px;
  top: 5px;
  text-align: center;
  color: red;
  font-weight: 500;
}

.both_error {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}

.active-text {
  color: #ffffff;
}

.file-upload-error {
  position: absolute;
  top: 0px;
  width: 100%;
}

.both_error .storage-error-msg {
  text-align: left;
}

.both_error .file-length-error-msg {
  text-align: right;
}

.upload-music-track-left-side {
  padding-right: 15px;
}

.data-not-found-div {
  min-height: 25.5vh;
}

.search-history-main-div {
  min-height: 40vh;
}

.storage-text-div {
  display: flex;
  align-items: center;
}

.info-icon-div {
  margin-left: 20px;
}

.info-icon-div i {
  font-size: 21px;
}

.info-icon-div i:hover {
  color: #dcba6c;
}

.storage-flex-div {
  display: flex;
  align-items: center;
}

.storage-flex-div .seperator {
  margin-right: 20px;
  margin-left: 20px;
}

.storage-text-div span {
  font-size: 12px;
  color: #ffffff;
  opacity: 0.7;
}

.storage-text-div p {
  color: #dcba6c;
  font-size: 17px;
  font-weight: 500;
}

.checkout-btn-div .bg-light-black {
  background-color: #1a1a1a !important;
  color: #FFFFFFB3;
  border: 2px solid #1a1a1a;
}

.checkout-btn-div .bg-light-black:hover {
  background-color: transparent !important;
  border: 2px solid #dcba6c;
  color: #dcba6c;
}

.checkout-btn-div .yellow-btn-hover {
  border: 2px solid #dcba6c;
  color: #001111 !important;
}

.checkout-btn-div .yellow-btn-hover:hover {
  color: #dcba6c !important;
}

.yellow-btn-hover-div {
  border: 2px solid #dcba6c;
}

.yellow-btn-hover-div:hover {
  background-color: transparent !important;
  border: 2px solid #dcba6c !important;
  color: #dcba6c !important;
}

.tracks-sec-items-div {
  min-height: inherit;
}

.explore-page-spacing {
  padding: 116px 0px 40px 40px !important;
}

.albums-title-desk-div {
  margin-bottom: 40px;
}

.buy-credits-btn {
  border: 2px solid #ffffff;
}

.buy-credits-btn:hover {
  border-color: #dcba6c !important;
}

.profile-seperator {
  margin-left: 20px;
  margin-right: 20px;
  width: 1px;
  background: transparent;
  border-right: 1px solid #5b6265;
  height: 16px;
}

.plan_left_div {
  display: flex;
  flex-direction: column;
}

.subscription-data-div {
  flex: 1;
}

.unlock-modal-center-line {
  max-height: 330px;
}

.purchased-tracks-btn-div {
  position: relative;
}

.purchased-tracks-btn-div .loader-img {
  position: absolute;
  left: -65px;
  top: -4px;
}

.upload-select-download-btn-div {
  position: relative;
}

.upload-select-download-btn-div .loader-img {
  position: absolute;
  right: 40px;
  top: -55px;
}

.trending-tracks-loader {
  position: absolute;
  right: 21px;
  top: 17px;
}

.store-albums-loader {
  position: absolute;
  right: 10px;
  top: -2px;
}

.css-u110ek-indicatorContainer {
  padding-right: 13px !important;
  padding-left: 0px !important;
}

.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}

.custom-select-filed .css-jvnggb-singleValue {
  padding-left: 7px !important;
}

.custom-select-filed .css-w8az5c-placeholder {
  padding-left: 7px !important;
}

.border-width-2 {
  border-width: 2px;
}

.custom-checkbox-div input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom-checkbox-div label {
  position: relative;
  cursor: pointer;
}

.custom-checkbox-div label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #575757;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
}

.custom-checkbox-div input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  width: 7px;
  height: 14px;
  border: solid #dcba6b;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.column-gap-25 {
  column-gap: 25px;
}

.css-1p7m7gu-singleValue,
.css-gfwxh6-placeholder {
  display: block !important;
  font-family: "Poppins", sans-serif !important;
}

.artist-table-main-div {
  border-radius: 8px;
  padding: 25px;
  background-color: #222222;
}

.artist-tabs-main-div {
  display: flex;
  align-items: center;
}

.artist-tabs-main-div .tabs-links {
  background-color: #333333;
  color: #ffffff;
  padding: 10px 20px;
  text-align: center;
  min-width: 120px;
  border: 1px solid #222222;
}

.artist-tabs-main-div .tabs-links.active {
  background-color: #dcba6c;
  color: #000 !important;
  font-weight: 500;
}

.artist-table-main-div .dashboard-table-title-div h4 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.artist-table-main-div .dashboard-table-card-div header {
  display: none;
}

.artist-table-main-div .dashboard-data-table-div .sc-eDWCr {
  font-size: 16px;
}




@media only screen and (min-width: 320px) and (max-width: 767px) {
  .plan_details_main_div {
    flex-wrap: wrap;
  }

  .search-history-main-div {
    min-height: inherit;
    margin-top: 0px !important;
  }

  .custom-checkbox-div label {
    font-size: 15px;
  }

  .custom-checkbox-div label:before {
    margin-right: 8px;
  }

  .plan_left_div {
    padding-right: 0px;
  }

  .plan_right_div {
    padding-left: 0px;
  }

  .screen_text_div {
    margin-top: 0px;
  }

  .modal_content {
    padding: 35px 20px;
    border-radius: 40px 40px 0px 0px !important;
    background-color: #000000 !important;
    margin: auto auto 0 auto !important;
    height: calc(100vh - 50px);
    max-height: calc(99vh - 50px);
    min-height: calc(99vh - 50px);
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
  }

  .modal_content .modal_body {
    flex: 1;
  }

  .modal {
    z-index: 999999;
  }

  .modal_dialog {
    background: #72706AB7 0% 0% no-repeat padding-box;
  }

  .screen_text_div {
    margin-bottom: 10%;
  }

  .modal_dialog {
    padding-left: 0px;
    padding-right: 0px;
  }

  .items_list_main_div {
    min-height: auto !important;
  }

  .mob_space_bottom {
    margin-bottom: 25px;
  }

  .modal_content .modal_header {
    margin-bottom: 25px !important;
    text-align: left;
    /* margin-top: 10px; */
  }

  .upload_tracks_modal_content {
    padding: 20px 10px !important;
  }

  .mob-uplolad-tracks {
    text-align: left;
  }

  .mob-uplolad-tracks button {
    margin-bottom: 10px;
  }

  .wavesurfer-play-push-icon-div button {
    width: 35px;
    height: 35px;
  }

  .wavesurfer-play-push-icon-div i {
    font-size: 22px;
  }

  .cus-row {
    margin-left: -0.9rem !important;
    margin-right: -0.9rem !important;
  }

  .mob-modal-btn-div {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  .mob-modal-btn-div button {
    width: 100%;
  }

  .footer-modal-sub-div .box-shadow-btn-mob {
    margin-bottom: 10px;
  }

  .footer-modal-sub-div .mob-modal-btn-div {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .choose-package-mob-div .flex-row-mob {
    display: flex;
    flex-wrap: unset;
    overflow-x: auto;
    background: #1D1D1D 0% 0% no-repeat padding-box;
    padding: 20px;
    border-radius: 20px;
  }

  .choose-package-mob-div .flex-row-mob .col-xs-12 {
    margin-bottom: 0px;
    margin-right: 15px;
    max-width: 80%;
  }

  .choose-package-mob-div .flex-row-mob .col-xs-12:last-child {
    margin-right: 0px;
  }

  .choose-package-mob-div .flex-row-mob .pricing-title-div {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .Albums-select-main-div .title-text {
    display: none;
  }

  .library-btntab-div span {
    background-color: #1A1A1A;
    padding: 12px 12px;
    border-radius: 8px;
    height: 42px !important;
    display: block;
    font-size: 14px;
  }

  .library-btntab-div span.active {
    background-color: #DCBA6C;
    color: #000 !important;
    border-bottom: 0px !important;
    font-weight: 500;
    border-radius: 30px;
  }

  .tabs-name-text {
    margin-left: 0px !important;
    margin-right: 0px !important;
    background-color: #1A1A1A;
  }

  .library-btntab-div {
    padding: 0px 0px;
    margin-bottom: 0px;
    width: 100%;
    text-align: center;
  }

  .mob-space-remove {
    padding-left: 0px !important;
  }

  .library-bar-main-div {
    justify-content: center;
    background-color: #222222;
    flex-wrap: unset !important;
    border-radius: 50px;
    overflow: hidden;
  }

  .categories-list-slider-div .react-multi-carousel-list .react-multi-carousel-track li {
    margin-right: 20px;
  }

  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .music-albums-main-div .react-multi-carousel-track .carousel-item:first-child {
    margin-left: 0px !important;
  }

  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 150px !important;
  }

  .music-albums-main-div .music_albums_globel_div .react-multi-carousel-track {
    overflow-x: hidden !important;
  }

  .tracks-div-global .react-multiple-carousel__arrow--right {
    left: calc(99% - 40px) !important;
    display: none;
  }

  .music_albums_globel_div .react-multiple-carousel__arrow--right {
    left: calc(99% - 40px) !important;
    display: none;
  }

  .categories-list-slider-div .react-multiple-carousel__arrow--left {
    left: calc(1% - -15px) !important;
    display: none;
  }

  .categories-list-slider-div .react-multiple-carousel__arrow--right {
    left: calc(99% - 52px) !important;
    display: none;
  }

  .subscription-details-btn-div {
    margin-top: 25px !important;
  }

  .subscription-details-btn-div .box-shadow-btn-mob {
    margin-left: 0px !important;
  }

  .search-opened-main-div .header-search-field-div {
    text-align: center;
    margin-bottom: 40px;
  }

  .search-opened-main-div .header-search-field-div input {
    padding-right: 40px;
    width: 100%;
    height: 50px;
    border-radius: 50px;
  }

  .search-opened-main-div .header-search-field-div i {
    font-size: 17px;
    top: 16px;
  }

  .search-opened-main-div .spinner-search-icon-mob {
    opacity: 1 !important;
    right: 12px !important;
    top: 4px !important;
    background-color: #000000 !important;
  }

  .search-opened-main-div .search-sec-main-div {
    margin-bottom: 30px;
  }

  .search-opened-main-div .search-history-main-div {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .search-opened-main-div .popular-searches-main-div {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .search-opened-main-div .top-results-main-div {
    margin-bottom: 30px;
  }

  .search-opened-main-div .music-albums-main-div {
    margin-top: 40px;
  }

  .mob-heading-hide {
    display: none;
  }

  .wavesurfer-title-wave-div {
    display: none;
  }

  .wavesurfer-main-div {
    align-items: center;
    flex-wrap: wrap;
  }

  .wavesurfer-control-div {
    width: 50%;
    max-width: 100%;
  }

  .wavesurfer-sound-div {
    width: 50%;
    max-width: 100%;
    display: flex;
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
    flex-direction: row-reverse;
  }

  .wavesurfer-sound-main-div {
    margin-right: 16px;
    display: flex;
  }

  .wavesurfer-sound-div i {
    font-size: 25px;
  }

  .wavesurfer-music-title-img-div {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-top: 14px;
  }

  /* .wavesurfer-sound-main-div {
    display: none;
  } */
  .wavesurfer-time-main-div {
    margin-right: 0px;
    min-width: 35px;
    text-align: right;
  }

  .wavesurfer-img-div {
    display: none;
  }

  .wavesurfer-soundbar-div {
    position: absolute;
    right: 0px;
    top: 35px;
    max-width: 125px;
    left: unset;
  }

  .audio-quality-modal-div .wavesurfer-sound-div .wavesurfer-soundbar-div {
    right: -73px !important;
    min-width: 100px;
    top: 35px;
  }

  #mob-checkout-btn-div button {
    background: #DCBA6C 0% 0% no-repeat padding-box;
    /* box-shadow: inset 0px -6px 0px #9e854d; */
    border: 1px solid transparent;
    border-radius: 15px !important;
    padding: 15px 15px 15px 15px !important;
    position: relative;
    font-weight: 600;
    width: 100%;
    font-family: "Poppins", sans-serif;
    color: #000000;
  }

  #mob-checkout-btn-div {
    margin-top: 20px !important;
  }

  .mob-uplolad-tracks .modal-yellowbtn-hover:hover {
    background-color: #dcba6c !important;
    color: #000000 !important;
  }

  .mob-checkout-space-div {
    margin-bottom: 0px !important;
  }

  .checkout-btn-div {
    display: flex;
    flex-direction: column-reverse;
  }

  .checkout-btn-div .cancel-btn-div {
    background-color: transparent !important;
    border: 2px solid #fff !important;
    width: 100% !important;
    padding: 10px 30px !important;
    font-size: 16px !important;
    color: #B3B3B3 !important;
    margin-top: 10px !important;
    margin-right: 0px !important;
  }

  .checkout-btn-div .box-shadow-btn-mob {
    border: 1px solid transparent !important;
    border-radius: 15px !important;
    padding: 15px 15px 15px 15px !important;
    font-weight: 600 !important;
    background-color: #DCBA6C !important;
    color: #000000 !important;
    width: 100% !important;
  }

  .purchased-tracks-div .purchased-tracks-btn-div .download-btn {
    font-size: 12px !important;
    padding: 6px 12px !important;
    margin-bottom: 0px !important;
  }

  .subscribe-now-radio-div {
    margin-bottom: 30px !important;
  }

  .audio-quality-modal-div .wavesurfer-title-wave-div {
    display: block;
  }

  .audio-quality-modal-div .wavesurfer-music-title-img-div .wavesurfer-img-div {
    display: block;
  }

  .audio-quality-modal-div .wavesurfer-music-title-img-div .wavesurfer-img-div {
    margin-right: 0px;
    width: 150px !important;
    height: 180px !important;
  }

  .audio-quality-modal-div .wavesurfer-music-title-img-div .wavesurfer-img-div img {
    height: 180px !important;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .audio-quality-modal-div .wavesurfer-music-title-div p {
    font-size: 16px;
    margin-top: 10px;
  }

  .purchased-tracks-btn-div .loader-img {
    left: -5px !important;
    top: 0px !important;
    width: 30px !important;
  }

  .trending-tracks-loader {
    position: absolute;
    right: 3px;
    top: 18px;
  }

  .home-faq-section-div {
    padding-top: 30px !important;
  }

  .header_scroll {
    -webkit-animation: none !important;
    animation: none !important;
  }

  .checkout-change-section-div {
    margin-top: 40px;
  }

  .albums-share-humburger-menu {
    top: 13px;
    /* right: -15px; */
  }

  .border-color {
    border-color: transparent !important;
  }

  #launcher {
    bottom: 80px !important;
    margin: 10px 15px !important;
  }

  .accept-invitation-modal-main-div {
    flex-wrap: wrap;
  }

  .Invitation-contain-sec {
    border-right: unset;
    margin-right: 0px;
    padding-right: 0px;
    border-bottom: 1px solid #dcba6c91;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .accept-invitation-modal-main-div {
    border: 1px solid #dcba6c91;
    background-color: #00000061;
    border-radius: 8px;
    padding: 25px 25px;
  }

  .Invitation-form-sec h2 {
    font-size: 24px;
  }

  .checkout-filed-div .form-control {
    height: 45px;
    padding: 8px 15px 8px 15px;
  }

  .checkout-select-field .css-14a5ikv-control {
    min-height: 45px !important;
    height: 45px !important;
  }

  .back_arrow_header {
    margin-right: 12px;
  }

  .back_arrow_header i:hover {
    color: #ffffff !important;
  }

  .mob-hide {
    display: none;
  }

  .artist-tabs-main-div .tabs-links {
    padding: 10px 5px;
    min-width: auto;
  }

  .artist-table-main-div {
    padding: 20px 10px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 380px) {
  .mob-header-title-text {
    font-size: 21px;
    color: #ffffff;
    font-weight: 600;
    position: relative;
    top: 8px;
    white-space: nowrap;
  }

  .header-upgrade-subscribe-text p {
    font-size: 13px !important;
    margin-right: 0px !important;
    color: #DBB96B;
    font-weight: bold;
  }

  .purchasing-detail-main-div .users_name {
    max-width: 140px;
  }

  .share-icon-box {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin: 0 auto;
  }

  .share-icon-main-div {
    column-gap: 20px;
  }

  .share-icon-box i {
    font-size: 22px;
  }

  .modal_content_share_album {
    min-height: 250px !important;
  }

  .modal_content_share_album .modal_header.m-b-40 {
    margin-bottom: 20px !important;
  }

  .modal_content_share_album .modal_header {
    margin-top: 0px !important;
  }

  .back_arrow_header i {
    font-size: 16px;
  }
}

@media only screen and (min-width: 381px) and (max-width: 767px) {
  .mob-header-title-text {
    font-size: 23px;
    color: #ffffff;
    font-weight: 600;
    position: relative;
    top: 10px;
    white-space: nowrap;
  }

  .header-upgrade-subscribe-text p {
    font-size: 15px !important;
    margin-right: 5px !important;
    color: #DBB96B;
    font-weight: bold;
  }

  .purchasing-detail-main-div .users_name {
    max-width: 175px;
  }

  .share-icon-box {
    width: 70px;
    height: 70px;
    border-radius: 12px;
    margin: 0 auto;
  }

  .share-icon-main-div {
    column-gap: 22px;
  }

  .share-icon-box i {
    font-size: 30px;
  }

  .modal_content_share_album {
    min-height: 275px !important;
  }

  .modal_content_share_album .modal_header.m-b-40 {
    margin-bottom: 20px !important;
  }

  .modal_content_share_album .modal_header {
    margin-top: 0px !important;
  }

  .back_arrow_header i {
    font-size: 20px;
    position: relative;
    top: 0px;
  }
}

/* ---------------------------------------MODEL CSS END------------------------------ */

/* ---------------FORM CONTROLS CSS START---------------------- */
.form-control {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #333;
  box-sizing: border-box;
  background-color: #333;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  height: 40px;
  padding-left: 15px;
}

/* ---------------FORM CONTROLS CSS END---------------------- */

/* DRAG AND DROP FILE CSS START*/
.uppy-Dashboard-inner {
  background-color: #222222 !important;
  border: 0px !important;
}

.uppy-Dashboard-dropFilesHereHint {
  background-color: #222222 !important;
}

[data-uppy-drag-drop-supported="true"] .uppy-Dashboard-AddFiles {
  height: calc(100% - 14px);
  margin: 7px;
  border: 2px dashed #dcba6b !important;
  border-radius: 3px;
}

.uppy-Dashboard-Item-action--edit {
  display: none !important;
}

.common-btn-div button {
  padding: 10px 25px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.border-bottom-hide {
  border-bottom: 0px !important;
}

/* DRAG AND DROP FILE CSS END*/

/* REACT_MULTI_CAROUSEL css start */

.carousel-container {
  display: flex !important;
  min-width: 10px !important;
}

.carousel-container-sm {
  display: flex !important;
  min-width: 10px !important;
  max-width: calc(100% - 300px) !important;
}

.react-multi-carousel-list {
  /* height: 80px; */
  position: unset !important;
  /* overflow: unset !important; */
  overflow-x: clip !important;
  overflow-y: initial !important;
}

.carousel-item {
  width: fit-content !important;
  max-width: fit-content !important;
  padding: 0px 0px;
}

.sub-header-main-div .react-multi-carousel-item {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.react-multiple-carousel__arrow {
  background-color: #333333 !important;
  min-height: 32px !important;
  min-width: 32px !important;
  z-index: unset !important;
}

.react-multiple-carousel__arrow:hover {
  background-color: #dcba6c !important;
}

.react-multiple-carousel__arrow::before {
  font-size: 16px !important;
}

/* REACT_MULTI_CAROUSEL css end  */

/* Tabs */
.tabs-name-text .active {
  color: #ffffff !important;
  border-bottom: 3px solid #DCBA6C;
}

.tabs-name-text span {
  padding-bottom: 6px;
}

.tabs-name-text span:hover {
  color: #dcba6c;
}

.tabs-name-text {
  margin-left: 20px;
  margin-right: 20px;
}

.play-push-box-main-div input[type="checkbox"] {
  height: 100%;
  width: 100%;
  background: transparent !important;
  border: 0px !important;
}

.play-push-box-main-div.common-checkbox-main-div input[type="checkbox"]:checked:before {
  width: 10px;
  top: 33px;
  left: 16px;
  transform: rotate(55deg);
}

.play-push-box-main-div.common-checkbox-main-div input[type="checkbox"]:checked:after {
  width: 20px;
  top: 30px;
  left: 21px;
  transform: rotate(-40deg);
}

/* tracks-div-global */
.tracks-div-global .react-multi-carousel-list {
  width: 100% !important;
}

.tracks-div-global .react-multi-carousel-track {
  width: 100%;
  /* overflow-y: hidden !important;
  overflow-x: hidden !important; */
}

.tracks-div-global {
  overflow-x: clip;
}

.tracks-div-global .react-multi-carousel-track .carousel-item {
  min-width: 344px;
  padding-right: 10px;
}

.react-multiple-carousel__arrow {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  position: relative;
  background-color: #ffffff !important;
  display: block;
}

.react-multiple-carousel__arrow:before {
  color: #000 !important;
  font-weight: bold;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% - -40px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(1% - 1px) !important;
}

.React-Player {
  display: none;
}

.banner-play-push-icon {
  font-size: 16px;
  position: relative;
  top: 2px;
}

/* red Dragon */
.music-albums-main-div .react-multi-carousel-list {
  width: 100% !important;
}

.music_albums_globel_div .react-multi-carousel-track {
  width: 100%;
  overflow-x: clip !important;
  overflow-y: unset !important;
  /* -webkit-column-gap: 21px;
     -moz-column-gap: 21px;
          column-gap: 21px;
  gap: 21px; */
  padding-left: 15px !important;
  /* padding-right: 15px !important; */
}

.music-albums-main-div .react-multi-carousel-track .carousel-item {
  min-width: 220px;
  /* width: 200px !important; */
  /* margin-left: 15px;
  margin-right: 15px; */
}

/* .music-albums-main-div .react-multi-carousel-track .carousel-item:first-child {
  margin-left: 0px;
}
.music-albums-main-div .react-multi-carousel-track .carousel-item:last-child {
  margin-right: 0px;
} */
.music-albums-main-div .react-multi-carousel-list {
  height: auto !important;
}

.music-albums-main-div .react-multi-carousel-list .react-multi-carousel-track {
  padding-top: 22px;
}

.music-albums-main-div {
  overflow-x: hidden;
  min-height: inherit;
}

.albums-listing-page-div {
  overflow-x: unset !important;
}

/* .categories_list .react-multi-carousel-list{
     overflow: unset !important;
   } */

.fileUpload-modal {
  height: 100% !important;
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  overflow: auto;
  background-color: #333;
  background-color: transparent;
  z-index: 999;
  right: 0;

  background: rgba(0, 0, 0, 0.5);
}

.fileUpload-modal-dialog {
  min-height: 100%;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

/* Modal Content */
.fileUpload-modal-content {
  padding: 30px 20px;
  position: relative;
  background-color: #222;
  margin: auto;
  width: 450px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.1s;
  animation-name: animatetop;
  animation-duration: 0.1s;
  border-radius: 8px;
}

.fileUpload-modal-content {
  padding: 15px 15px;
  position: relative;
  background-color: #222;
  margin: auto;
  width: 100%;
  max-width: 700px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: .1s;
  animation-name: animatetop;
  animation-duration: .1s;
  border-radius: 8px;
}

.fileUpload-modal-header {
  color: #fff;
  font-size: 18px;
  margin-bottom: 0px !important;
}

.fileUpload-modal-header i:hover {
  color: #dcba6c;
}

.minimize-upload-track {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  max-width: 130px;
  margin-right: 30px;
}

.icon-flip-div {
  transform: rotateY(180deg);
  position: absolute;
  left: 0px;
  top: -14px;
}

.upload-track-title {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 400;
}

.header-right-side-main-dev {
  display: flex;
  align-items: center;
}

.clear-search-icon {
  right: 15px !important;
  left: unset !important;
  color: white;
  opacity: 0.7;
}

.spinner-search-icon {
  position: absolute !important;
  right: 33px !important;
  left: unset !important;
  color: #9e854d !important;
  font-size: 20px !important;
  top: 2px !important;
  z-index: 99;
  background-color: #111111;
}

.spinner-library-search-icon {
  left: unset !important;
  color: #9e854d !important;
  opacity: 1;
  font-size: 22px !important;
  top: 0px !important;
  right: 8px;
  position: absolute !important;
  background-color: #222222 !important;
  z-index: 999;
}

.close-icon-modal {
  position: relative;
  top: -15px;
  font-size: 22px;
}

/* Tracks carousel media css */
@media only screen and (min-width: 768px) and (max-width: 810px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 290px !important;
  }

  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 200px;
  }
}

@media only screen and (min-width: 811px) and (max-width: 991px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 280px !important;
  }

  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 210px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1040px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 286px !important;
  }

  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 200px;
  }
}

@media only screen and (min-width: 1041px) and (max-width: 1199px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 325px !important;
  }

  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 210px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 350px !important;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 370px !important;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1500px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 305px !important;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1600px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 320px !important;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1700px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 340px !important;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 360px !important;
  }
}

@media only screen and (min-width: 1801px) and (max-width: 1900px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 320px !important;
  }
}

@media only screen and (min-width: 1901px) and (max-width: 2200px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 342px !important;
  }
}

@media only screen and (min-width: 2201px) and (max-width: 2400px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 330px !important;
  }
}

@media only screen and (min-width: 2401px) and (max-width: 2700px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 315px !important;
  }
}

@media only screen and (min-width: 2701px) and (max-width: 3000px) {
  .tracks-div-global .react-multi-carousel-track .carousel-item {
    min-width: 312px !important;
  }
}




/* TrendingAlbums media css */

@media only screen and (min-width: 768px) and (max-width: 810px) {
  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 200px;
  }
}

@media only screen and (min-width: 811px) and (max-width: 991px) {
  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 210px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1040px) {
  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 200px;
  }
}

@media only screen and (min-width: 1041px) and (max-width: 1120px) {
  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 210px;
  }
}

@media only screen and (min-width: 1121px) and (max-width: 1199px) {
  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 220px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 200px;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1500px) {
  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 200px;
  }
}

@media only screen and (min-width: 1501px) and (max-width: 1600px) {
  .music-albums-main-div .react-multi-carousel-track .carousel-item {
    min-width: 210px;
  }
}

.free-to-stream {
  text-align: center;
  margin-top: 50px;
}

.free-to-stream i {
  font-size: 30px;
  color: #BDBDBD;
  font-weight: 400;
}

.free-to-stream p {
  text-align: center;
  font-size: 24px;
  color: #BDBDBD;
}

.active-price-backGround {
  border: 1px solid #dcba6c;
  box-shadow: rgb(220 186 108 / 38%) 0px 20px 30px -10px;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: linear;
  /* transform: translateY(-10px); */
}

.wrapper {
  margin-bottom: 5px;
  width: 100%;
  min-width: 160px;
  height: 16px;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}



input,
textarea,
button,
select,
div,
span,
a {
  -webkit-tap-highlight-color: transparent;
}


.categories-list-btn-slider .react-multi-carousel-track .react-multi-carousel-item {
  margin-right: 20px;
}

.dashboard-datatable-bar-div .selectbox .css-vpeu17-control,
.dashboard-datatable-bar-div .selectbox .css-xesomj-control {
  background-color: transparent;
  height: 40px;
}

.dashboard-datatable-bar-div .selectbox .css-vpeu17-control .css-gfwxh6-placeholder,
.dashboard-datatable-bar-div .selectbox .css-xesomj-control .css-gfwxh6-placeholder,
.dashboard-datatable-bar-div .selectbox .css-1p7m7gu-singleValue,
.dashboard-datatable-bar-div .selectbox-2 #react-select-5-placeholder {
  padding: 0 10px;
  color: #b4b4b4;
}

.selectbox-2 {
  min-width: 150px !important;
}

.dashboard_select_width {
  width: 350px !important;
}

.css-12m4v9-menu {
  background-color: #222222 !important;
  border: none !important;

}

@media only screen and (max-width: 991px) {
  .dashboard-table-card-div .dashboard-table-box-div {
    flex-wrap: wrap;
  }

  .dashboard-table-title-div {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width:608px) {
  .dashboard-table-card-div .d-flex.dashboard-datatable-bar-div {
    justify-content: flex-start;
    gap: 10px;
  }

  /* .dashboard-table-card-div .d-flex.dashboard-datatable-bar-div .dashboard-search-div {
    margin-top: 15px;
  } */
}

@media only screen and (max-width:356px) {
  .dashboard-table-card-div .d-flex.dashboard-datatable-bar-div {
    display: block;
  }

  .dashboard-datatable-bar-div .selectbox-2 {
    margin-top: 15px;
  }

  .dashboard-table-card-div .d-flex.dashboard-datatable-bar-div .m-r-15 {
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 767px) {

  .epic-audio-img,
  .soundboard-img,
  .cast-img {
    width: 100%;
    height: 361px;
    background-size: 100%;
  }

}

@media only screen and (max-width: 600px) {

  .epic-audio-img,
  .soundboard-img,
  .cast-img {
    width: 100%;
    height: 250px;
    background-size: contain;
  }
}

@media only screen and (max-width: 480px) {

  .epic-audio-img,
  .soundboard-img,
  .cast-img {
    width: 100%;
    height: 200px;
    background-size: contain;
  }
}

.header-small-menu-div .header-upgrade-subscribe-text .user-profile-div {
  width: 45px;
  height: 45px;
  background: #222222;
  display: inline-block;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.album-select-box-main-div .css-1p7m7gu-singleValue {
  display: inline-grid;
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: 0px min-content;
  min-width: 135px;
}

.album-select-box-main-div .css-gfwxh6-placeholder {
  display: inline-grid;
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: 0px min-content;
  min-width: 135px;
}

.album-select-box-main-div .css-1hb7zxy-IndicatorsContainer,
.dashboard-datatable-bar-div .css-1hb7zxy-IndicatorsContainer {
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  min-width: 73px;
  justify-content: space-between;
}

.dashboard-datatable-bar-div .css-1p7m7gu-singleValue,
.dashboard-datatable-bar-div .css-gfwxh6-placeholder {
  min-width: 170px;
}

.select-trial-div .css-gfwxh6-placeholder,
.select-trial-div .css-1p7m7gu-singleValue {
  min-width: 110px;
}

.album-select-box-main-div .css-1wy0on6,
.dashboard-datatable-bar-div .css-1wy0on6 {
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  min-width: 73px;
  justify-content: space-between;
}

/* Subscription cancel or save plan btn css */
/* @media only screen and (max-width: 374px) {
  .subscription-details-btn-div.mob-modal-btn-div.modal-btn-space-div {
    margin-top: 48% !important;
  }
}
@media only screen and (max-width: 420px) {
  .subscription-details-btn-div.mob-modal-btn-div.modal-btn-space-div {
    margin-top: 65% !important;
  }
} */